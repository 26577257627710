import React, { useCallback, useState } from "react"
import DetailedReceiptModal from "shared/Modules/OrderDetails/Screens/DetailedReceiptModal"
import { IRecentPurchaseDetails } from "../../userCardsTypes"

type AdapterProps = Readonly<{
    order: IRecentPurchaseDetails
    onClose: () => void
}>

function DetailedReceiptAdapter({ order, onClose }: AdapterProps) {
    return (
        <>
            {order && (
                <DetailedReceiptModal
                    order={{
                        id: order.content.orderId,
                        uid: order.content.orderUid,
                        orderType: order.content.orderType ?? "",
                    }}
                    open={!!order}
                    onClose={onClose}
                />
            )}
        </>
    )
}

export function useDetailedReceiptModal() {
    const [order, setOrder] = useState<IRecentPurchaseDetails>()

    const showDetailedReceiptModal = useCallback((order: IRecentPurchaseDetails) => setOrder(order), [setOrder])
    const handleClose = useCallback(() => setOrder(undefined), [setOrder])

    const detailedReceiptModalAdapter = useCallback(
        () =>
            order
                ? DetailedReceiptAdapter({
                      order: order,
                      onClose: handleClose,
                  })
                : null,
        [order, handleClose]
    )

    return {
        DetailedReceiptModalAdapter: detailedReceiptModalAdapter,
        showDetailedReceiptModal,
    }
}
