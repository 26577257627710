import { negotiateLanguages } from "@fluent/langneg"
import { Logger } from "shared/Helpers/logging"

export const DEFAULT_LOCALE = "en"
const RE_VALID_KEY = /^(-?[a-zA-Z][\w-]*)$/
const RE_ENUM_REPLACE = /_/g

const logger = new Logger("localization")
const isValidKey = (key: string): boolean => RE_VALID_KEY.test(key)

export function buildFtl(locale: string, messages: object): string {
    let ftl = "";
    for (const [key, value] of Object.entries(messages)) {
        ftl += `${key} = ${value}\n\n`

        if (!isValidKey(key)) {
            // Fluent doesn't seem to report syntax errors in any way
            logger.warn(`Invalid key in translation for ${locale}: '${key}' [will use fallback text]`)
        }
    }
    return ftl
}

export function pickBestLocale(savedUserLocale?: string): string {
    const userLocales = savedUserLocale ? [savedUserLocale, ...navigator.languages] : navigator.languages
    const supportedLocales = Object.keys(defaultTranslations)

    logger.info("Picking best match between user locales and supported locales", userLocales, supportedLocales)

    const selectedLocale = negotiateLanguages(userLocales, supportedLocales, { strategy: "lookup", defaultLocale: DEFAULT_LOCALE })
    logger.info("Selected locale(s)", selectedLocale)

    return selectedLocale[0]
}

export function getLocalizationIdForEnum(base: string, enumValue: string) {
    const id = `${base}-${enumValue.toLowerCase().replace(RE_ENUM_REPLACE, "-")}`
    logger.debug(`Localization id for base '${base}' and enum value '${enumValue}': ${id}`)
    return id
}

export const defaultTranslations = {
    "da-DK": {
        // Branding
        "-gopay-app-name": "GoPay",
        "-gopay-wallet": "GoPay Wallet",


        // Date and time formats as Luxon format strings
        // See: https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
        "date-time-format-date": "dd/MM/yyyy",
        "date-time-format-day-and-date": "EEEE dd/MM",
        "date-time-format-time": "HH:mm",
        "date-time-format-date-and-time": "dd/MM/yyyy\u00A0HH:mm",
        "date-time-format-duration-hours-minutes": "h't.' m'min.'",
        "date-time-format-duration-minutes": "m'min.'",
        "date-time-format-duration-seconds": "s's'",
        "date-time-format-month-and-year": "MMMM, yyyy",


        // success / error handling
        "success-modal-status": "\n .buttonLabel = Luk",
        "error-route-status": "\n .topbarTitle = Fejl",
        "error-dialog-close-button": "\n .buttonLabel = Luk",
        "action-failed-modal": "\n .buttonLabel = Luk",
        "errorHandler-labels": "\n    .title = Noget gik galt\n    .buttonLabel = Gå til forside",
        "errorHandlerModal-labels": "\n    .title = Noget gik galt\n    .buttonLabel = Luk",
        "error-clientNetworkError-label" : "Ingen Internet forbindelse",
        "unknown-error-title": "Ukendt fejl",
        "unknown-error-message": "Ingen fejloplysninger tilgængelige",
        "general-error-title": "Noget gik galt",
        "internal-error-title": "Intern fejl",
        "api-call-error-title-network-error": "Netværksfejl",
        "api-call-error-title-bad-request": "Forespørgsel afvist",
        "api-call-error-title-unauthorized": "Ugyldigt login",
        "api-call-error-title-forbidden": "Adgang nægtet",
        "api-call-error-title-not-found": "Ikke fundet",
        "api-call-error-title-conflict": "Konflikt",
        "api-call-error-title-too-many-requests": "For mange forespørgsler",
        "api-call-error-title-internal-server-error": "Intern server fejl",
        "api-call-error-title-service-unavailable": "Service ikke tilgængelig",
        "api-call-error-title-request-failed": "Forespørgsel fejlede",
        "api-call-error-no-internet-connection": "Ingen Internet forbindelse",
        "api-call-error-could-not-reach-server": "Kunne ikke opnå forbindelse til server",
        "api-call-error-protocol-error": "Server fejl: { $responseCode } { $responseStatus }",
        "api-call-error-api-returned-error": "Uventet server fejl",
        "api-call-error-response-not-json": "Uventet svar fra server",
        "api-call-error-retry-button": "Forsøg igen",


        // authenticate common
        "-authenticate-submit-button": "Næste",


        // order types shared translations
        "-order-type-lunch": "Dagskøb",
        "-order-type-take-away": "Takeaway",
        "-order-type-catering": "Mødeforplejning",
        "-order-type-meeting": "{ $count -> \n *[one] Booking \n [other] Bookinger \n }",
        "-order-type-refill-account": "{ $count -> \n *[one] Optankning \n [other] Optankninger \n }",
        "-order-type-auto-refill-account": "Automatisk optankning",
        "-order-type-lunch-subscription": "{ $count -> \n *[one] Abonnement \n [other] Abonnementer \n }",
        "-order-type-subscribe-payment-card": "Tilmeldt betalingskort",
        "-order-type-refund": "{ $count -> \n *[one] Refundering \n [other] Refunderinger \n }",


        // user prompts
        "user-prompt-button-label-saving": "\n .loadingLabel = Gemmer...",


        // welcome
        "authenticate-welcome-slide-header-buy-lunch": "Køb frokost og firmaydelser",
        "authenticate-welcome-slide-text-buy-lunch": "Køb nemt din mad hos frokostleverandøren samt andre firmaydelser, f.eks. forplejning ved events og tøjrens.",
        "authenticate-welcome-slide-header-reduce-food-waste": "Bekæmp madspild",
        "authenticate-welcome-slide-text-reduce-food-waste": "Forudbestil og afbestil din frokost, så køkkenet kan planlægge madproduktionen. Vælg fremhævede menuer, der er bedre for miljøet.",
        "authenticate-welcome-slide-header-receive-offers": "Ugens menuer og tilbud",
        "authenticate-welcome-slide-text-receive-offers": "Du kan se ugens menuer med næringsindhold, modtage tilbud og læse nyheder.",
        "authenticate-welcome-slide-header-purchase-overview": "Se dine køb",
        "authenticate-welcome-slide-text-purchase-overview": "Du har et godt overblik over dine køb og kvitteringer. Betaler du via løntræk, kan du nemt afstemme.",
        "authenticate-welcome-continue-button": "Log ind / Opret konto",


        // login choices
        "authenticate-login-choices-header": "Bestil mad og andre services",
        "authenticate-login-choices-login-button": "Log ind",
        "authenticate-login-choices-create-account-button": "Opret konto",
        "authenticate-login-choices-guest-link": "Køb som gæst",


        // login / signup email
        "authenticate-email-header": "Indtast din e-mail adresse",
        "authenticate-email-signup-header": "Opret konto",
        "authenticate-email-email-input": "\n    .label = E-mail adresse",
        "authenticate-email-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Checker e-mail adresse...",
        "authenticate-email-email-is-required": "Indtast din e-mail adresse",
        "authenticate-email-email-must-be-an-email": "Ugyldig e-mail adresse",
        "authenticate-email-expect-company-flow": "Du forsøger at oprette en konto med e-mail\u00ADdomænet <emailDomain>{ $emailDomain }</emailDomain>, der er tilknyttet en virksomhed. Hvis du ønsker at oprette en privat konto, skal du bruge din private e-mail\u00ADadresse.",
        "authenticate-email-expect-private-flow": "Dit e-mail\u00ADdomæne <emailDomain>{ $emailDomain }</emailDomain> er i øjeblikket ikke tilknyttet nogen virksomhed i systemet. Du skal bruge din arbejds\u00ADmail og ikke din private e-mail\u00ADadresse. Kontakt din administrator, hvis du mener, at dit domæne burde være tilknyttet en virksomhed.",
        "authenticate-email-switch-to-login-flow": "Den indtastede e-mail\u00ADadresse findes allerede i systemet. Ønsker du at logge ind i stedet?",
        "authenticate-email-switch-to-signup-flow": "Den indtastede e-mail\u00ADadresse fandtes ikke i systemet. Ønsker du at oprette en konto i stedet?",
        "authenticate-email-switch-flow-no-button": "Nej tak",
        "authenticate-email-switch-flow-yes-button": "Ja tak",
        "authenticate-email-okay-button": "Forstået",


        // activate by phone
        "authenticate-phone-number-header": "Aktiver konto",
        "authenticate-phone-number-text": "Vi har brug for at kende dit mobilnummer, så vi kan sende en aktiveringskode:",
        "authenticate-phone-number-phone-number-input": "\n .label = Telefonnummer",
        "authenticate-phone-number-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Sender kode...",
        "authenticate-phone-number-username-missing": "Brugernavn / e-mail mangler. Gå venligst tilbage og udfyld.",
        "authenticate-phone-number-phone-number-is-required": "Telefonnummer er påkrævet",
        "authenticate-phone-number-phone-number-is-invalid": "Ugyldigt telefonnummer",


        // activation code
        "authenticate-activation-code-header": "Aktiver konto",
        "authenticate-activation-code-automatic-activation-text": "Vent venligst mens vi aktiverer din konto...",
        "authenticate-activation-code-manual-activation-text": "Indtast venligst den kode, som du har modtaget på e-mail eller SMS:",
        "authenticate-activation-code-activation-code-input": "\n .label = Aktiveringskode",
        "authenticate-activation-code-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Aktiverer...",
        "authenticate-activation-code-code-field-required": "Indtast en aktiveringskode",
        "authenticate-activation-code-code-field-min-len": "Aktiveringskode er på min. { $codeLength } tegn",


        // login/password
        "authenticate-password-header": "Indtast dit kodeord",
        "authenticate-password-header-with-name": "Velkommen { $displayName }! Log venligst ind.",
        "authenticate-password-password-input": "\n .label = Kodeord",
        "authenticate-password-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Logger ind...",
        "authenticate-password-username-missing": "Brugernavn / e-mail mangler. Gå venligst tilbage og udfyld.",
        "authenticate-password-forgot-password-link": "Glemt kodeord?",
        "authenticate-password-password-is-required": "Kodeord skal indtastes",


        // login/single sign on
        "authenticate-sso-header": "Single Sign-on",
        "authenticate-sso-explanation": "Din organisation { $organization } benytter Single Sign-on. Du vil nu blive sendt til jeres fælles login side.",
        "authenticate-sso-canceled-message": "Du gennemførte ikke login processen.",
        "authenticate-sso-retry-button": "Prøv igen\n .loadingLabel = Viderestiller...",


        // login/account-type
        "authenticate-account-type-choose-account-type": "Vælg kontotype",
        "authenticate-account-type-private-account": "Privat konto",
        "authenticate-account-type-company-account": "Firmakonto",
        "authenticate-account-type-private-account-description": "Med en privat konto kan du tilmelde dig med din private e-mailadresse og få adgang til offentlige shops. Du har ikke adgang til firma shops med denne kontotype.",
        "authenticate-account-type-company-account-description": "En firmakonto giver dig adgang til de shops, der er tilknyttet din virksomhed. Du skal bruge din arbejdsmail for at oprette en konto.",
        "authenticate-account-type-help-text": "Skal jeg bruge en privat konto eller en virksomhedskonto?",
        "authenticate-account-type-help-drawer": "### Firmakonto\n\n Firmaer og andre organisationer kan udbyde forskellige services med betaling via GoPay, f.eks. køb af kantinemad. For at tilgå disse shops, skal du have en firmakonto. Vi finder frem til dit firma ud fra dit e-mail\u00ADdomæne.\n\n ### Privat konto\n\n Udover firma shops findes der også offentlige shops i GoPay, som privat\u00ADpersoner kan købe i. Med en privat konto har du adgang til alle offentlige shops.\n   .title = Kontotype forklaring",


        // select company
        "authenticate-select-company-header-reason-multiple-companies-for-domain": "Vælg firma",
        "authenticate-select-company-header-reason-multiple-accounts-for-username": "Vælg brugerkonto",
        "authenticate-select-company-reason-message-multiple-companies-for-domain": "Vi var ikke i stand til at bestemme dit firma automatisk, da dit e-mail domæne <duplicateValue>{ $duplicateValue }</duplicateValue> optræder flere steder i vores system.",
        "authenticate-select-company-reason-message-multiple-accounts-for-username": "Vi fandt flere konti i vores system med e-mail adressen <duplicateValue>{ $duplicateValue }</duplicateValue>.",
        "authenticate-select-company-action-message-multiple-companies-for-domain": "Vælg lokation (firma), som du ønsker at oprette din konto under:",
        "authenticate-select-company-action-message-multiple-accounts-for-username": "Vælg venligst den brugerkonto, som du ønsker at logge ind med.",
        "authenticate-select-company-show-list-button": "Vis valgmuligheder",
        "authenticate-select-company-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Gemmer...",


        // sign up
        "authenticate-signup-header" : "Opret brugerkonto",
        "authenticate-signup-text" : "Vi har brug for at vide lidt mere om dig, så vi kan oprette din konto:",
        "authenticate-signup-name-input": "\n .label = Navn",
        "authenticate-signup-mobile-input": "\n .label = Mobil (valgfrit)",
        "authenticate-signup-accept-terms-link": "Jeg accepterer brugsbetingelserne",
        "authenticate-signup-submit-button": "{ -authenticate-submit-button }",
        "authenticate-signup-username-missing": "Brugernavn / e-mail mangler. Gå venligst tilbage og udfyld.",
        "authenticate-signup-name-is-required": "Navn er påkrævet",
        "authenticate-signup-name-must-be-full-name": "Vi skal bruge dit fulde navn (fornavn og efternavn)",
        "authenticate-signup-phone-number-is-invalid": "Ugyldigt telefonnummer",
        "authenticate-signup-terms-must-be-accepted": "Du skal acceptere betingelserne for at bruge appen",


        // confirm account details
        "authenticate-confirm-account-details-header": "Bekræft oplysninger",
        "authenticate-confirm-account-details-email-field": "\n .primary = E-mail",
        "authenticate-confirm-account-details-name-field": "\n .primary = Navn",
        "authenticate-confirm-account-details-mobile-field": "\n .primary = Mobil",
        "authenticate-confirm-account-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Opretter din konto...",
        "authenticate-confirm-account-details-account-missing": "Kontodetaljer mangler. Gå venligst tilbage og udfyld.",


        // create password
        "authenticate-create-password-header": "Opret kodeord",
        "authenticate-create-password-text": "Du skal oprette et kodeord for at kunne tage din konto i brug:",
        "authenticate-create-password-password-input": "\n .label = Kodeord",
        "authenticate-create-password-repeat-password-input": "\n .label = Gentag kodeord",
        "authenticate-create-password-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Opretter dit kodeord...",
        "authenticate-create-password-one-time-token-missing": "Kan ikke finde engangs-token. Gå venligst et skridt tilbage og prøv igen.",
        "authenticate-create-password-password-is-required": "Du skal indtaste et kodeord",
        "authenticate-create-password-password-too-short": "Dit kodeord skal være på mindst { $minPasswordLength } tegn",
        "authenticate-create-password-repeat-is-required": "Du skal gentage dit kodeord",
        "authenticate-create-password-password-mismatch": "Dine kodeord stemmer ikke overens",


        // reset password
        "authenticate-reset-password-header": "Nulstil kodeord",
        "authenticate-reset-password-button": "Nulstil\n .loadingLabel = Nulstiller...",
        "authenticate-reset-password-username-missing": "Brugernavn / e-mail mangler. Gå venligst tilbage og udfyld.",


        // login route components
        "login-external-guest-dialog": "\n .title = Gæstekøb\n .message = Du er ved at foretage gæstekøb. Shoppen åbnes i et nyt vindue.\n .buttonLabel = Fortsæt",


        // --- User cards ---
        "user-cards-loading-element": "\n .title = Indlæser kort...",


        // home
        "-show-all": "Se alle",
        "-home-offers": "Gode tilbud",
        "-home-webshops": "Webshops",
        "-home-my-favorites": "Mine favoritter",

        "home-label": "Hjem",

        "home-news": "\n    .title = Nyheder\n    .rightOptionText = { -show-all }",
        "home-news-kitchen": "\n    .title = Nyt fra kantinen\n    .rightOptionText = { -show-all }",
        "home-news-company-and-service-center": "\n    .title = Nyt fra {$company}\n    .rightOptionText = { -show-all }",
        "home-news-facilitynet": "\n    .title = Nyt fra { -gopay-app-name }\n    .rightOptionText = { -show-all }",

        "home-my-meetings": "\n    .title = Kommende møder\n    .rightOptionText = { -show-all }",
        "home-upcoming-meetings": "Kommende møder",
        "home-contact-the-canteen": "Kontakt kantinen",
        "home-no-planned-meetings": "Ingen planlagte møder",
        "home-plan-meeting": "Planlæg et møde",

        "home-you-are-here": "Du er her:",
        "home-change-location": "Skift lokation",

        "home-takeaway-offers": "\n     .title = { -home-offers } \n     .rightOptionText = { -show-all }",
        "home-all-takeaway-offers-title": "\n   .primaryTopBarTitle = { -home-offers }",

        "home-takeaway-shops": "\n      .title = { -home-webshops } \n      .rightOptionText = { -show-all }",
        "home-all-takeaway-shops-title": "\n   .primaryTopBarTitle = { -home-webshops }",

        "home-product-favorites": "\n      .title = { -home-my-favorites } \n      .rightOptionText = { -show-all }",
        "home-all-product-favorites-title": "\n   .primaryTopBarTitle = { -home-my-favorites }",
        "home-all-product-favorites-empty": "Du har ingen favoritter.",
        "home-product-favorites-options": "\n .loadingDescription = Fjerner favorit...",
        "home-remove-favorite": "Fjern favorit",

        "home-user-card-survey-rate-now": "Bedøm nu",

        "home-user-card-check-in-reminder-check-in": "Tak fordi du checkede ind i dag",
        "home-user-card-check-in-reminder-no-check-in": "Husk at checke ind i kantinen i dag",
        "home-user-card-check-in-reminder-learn-more": "Læs mere",

        "home-user-card-recent-purchase-view-receipt": "Vis kvittering",
        "home-user-card-recent-purchase-status": "Status: { $status }",

        // user survey modal
        "user-survey-step2-question-good": "Hvad gjorde vi særlig godt?",
        "user-survey-step2-question-bad": "Hvad kunne vi have gjort bedre?",
        "user-survey-add-comment-button": "\n {$comment ->\n      *[0] Skriv en\n      [other] Rediger\n} kommentar",
        "user-survey-thank-you-message": "Tak fordi du ville deltage",
        "user-survey-next-button": "Næste",
        "user-survey-skip-button": "Spring over",
        "user-survey-submit-button": "Indsend\n .loadingLabel = Sender...",
        "user-survey-close-button": "Luk",
        "user-survey-comment-drawer": "\n   .title = Kommentar",
        "user-survey-comment-field": "\n   .label = Skriv din kommentar her",
        "user-survey-comment-submit-button": "Gem",
        "user-survey-comment-cancel-button": "Annuller",

        // navigation
        "bottom-navigation-home": "\n   .label = Forside",
        "bottom-navigation-todays-purchases": "\n   .label = I dag",
        "bottom-navigation-order": "\n   .label = Bestil",
        "bottom-navigation-messages": "\n   .label = Beskeder",
        "bottom-navigation-me": "\n   .label = Mig",

        "app-navigation-to-webshop-select": "Gå til webshops",
        "app-navigation-to-login": "Gå til log ind",
        "app-navigation-to-home": "Gå til forside",


        // Consent dialog
        "consent-dialog-personal-information-header": "Personoplysninger",
        "consent-dialog-pii-category-contact-info": "Kontaktinformation",
        "consent-dialog-pii-category-identifiers": "Identifikation",
        "consent-dialog-pii-category-purchases": "Køb",
        "consent-dialog-pii-category-user-content": "Brugerindhold",
        "consent-dialog-pii-item-name": "Navn",
        "consent-dialog-pii-item-email-address": "E-mail",
        "consent-dialog-pii-item-phone-number": "Telefonnummer",
        "consent-dialog-pii-item-user-id": "Bruger id",
        "consent-dialog-pii-item-device-id": "Device id",
        "consent-dialog-pii-item-customer-support": "Kundeservice",
        "consent-dialog-pii-item-purchase-history": "Købshistorik",
        "consent-dialog-privacy-policy-header": "Privatlivspolitik",
        "consent-dialog-privacy-policy-link": "Se firmaets privatlivspolitik",


        // My meetings
        "my-meetings-sittings": "{$amount} kuv.",
        "my-meetings-header-label": "\n   .primaryTopBarTitle = Mine møder",
        "my-meetings-now-badge": "\n      .label = Nu",
        "my-meetings-search-label": "\n   .label = Søg",
        "my-meetings-no-filtered-label": "Ingen matchende møder",
        "my-meetings-no-meetings-label": "Ingen planlagte møder",


        // Meeting details
        "meeting-details-delete-dialog": "\n  .description = Afbestil levering d. {$date}?\n .loadingDescription = Sletter levering...\n  .confirmLabel = Slet",


        // Todays purchases
        "todays-orders-receipt-top-bar-title": "Dagens køb",
        "todays-orders-receipt-top-bar-title-length": "Dagens køb {$selectedOrder} af {$maxOrders}",
        "todays-orders-none-found": "Du har ingen køb i dag.",
        "todays-orders-no-such-order": "Ordren findes ikke",


        // My orders
        "my-orders-page": "\n  .primaryTopBarTitle = Mine ordrer",
        "my-orders-page-header": "\n .title = Mine ordrer",

        // My Orders tabs
        "my-orders-tab-purchases": "\n .label = Køb",
        "my-orders-tab-wallet-refills": "\n .label = Optankning",

        // My Orders expenses chart tabs
        "my-orders-expenses-chart-tab-last-week": "\n .label = Sidste 7 dage",
        "my-orders-expenses-chart-tab-last-month": "\n .label = Sidste 30 dage",
        "my-orders-expenses-chart-tab-last-year": "\n .label = Sidste 365 dage",
        "my-orders-expenses-chart-title": "Privatkøb",

        // My Orders filter button
        "my-orders-filter-button-none": "Ingen",
        "my-orders-filter-button-all": "Alle",
        "my-orders-filter-button-some": "{$selected} / {$available} valgte",
        "my-orders-filter-button-lunch": "{ -order-type-lunch(count: 100) }",
        "my-orders-filter-button-take-away": "{ -order-type-take-away(count: 100) }",
        "my-orders-filter-button-catering": "{ -order-type-catering(count: 100) }",
        "my-orders-filter-button-meeting": "{ -order-type-meeting(count: 100) }",
        "my-orders-filter-button-refill-account": "{ -order-type-refill-account(count: 100) }",
        "my-orders-filter-button-auto-refill-account": "{ -order-type-auto-refill-account(count: 100) }",
        "my-orders-filter-button-lunch-subscription": "{ -order-type-lunch-subscription(count: 100) }",
        "my-orders-filter-button-subscribe-payment-card": "{ -order-type-subscribe-payment-card(count: 100) }",
        "my-orders-filter-button-refund": "{ -order-type-refund(count: 100) }",

        // My Orders filter order types
        "my-orders-filter-order-type-lunch": "\n .label = { -order-type-lunch(count: 100) }",
        "my-orders-filter-order-type-take-away": "\n .label = { -order-type-take-away(count: 100) }",
        "my-orders-filter-order-type-catering": "\n .label = { -order-type-catering(count: 100) }",
        "my-orders-filter-order-type-meeting": "\n .label = { -order-type-meeting(count: 100) }",
        "my-orders-filter-order-type-refill-account": "\n .label = { -order-type-refill-account(count: 100) }",
        "my-orders-filter-order-type-auto-refill-account": "\n .label = { -order-type-auto-refill-account(count: 100) }",
        "my-orders-filter-order-type-lunch-subscription": "\n .label = { -order-type-lunch-subscription(count: 100) }",
        "my-orders-filter-order-type-subscribe-payment-card": "\n .label = { -order-type-subscribe-payment-card(count: 100) }",
        "my-orders-filter-order-type-refund": "\n .label = { -order-type-refund(count: 100) }",

        "my-orders-filter-modal": "\n .title = Ordretyper\n .cancelButtonLabel = Luk",
        "my-orders-filter-order-type-label": "Ordretyper",
        "my-orders-results": "{$amount} resultater",
        "my-orders-load-more-button": "Indlæs flere",
        "my-orders-price-total": "I alt",
        "my-orders-print": "Print",
        "my-orders-edit": "Rediger",
        "my-orders-share": "Del",
        "my-orders-delete": "Slet",
        "my-orders-receipt": "Kvittering",


        // Transactions
        "transaction-generic-processing": "Vent venligst...",
        "transaction-generic-success": "Transaktionen er gennemført",


        // Webshop - payment methods
        "-payment-accept-terms": "Jeg accepterer handelsbetingelser",

        "payment-method-initial-message": "Vælg betalingsmetode",
        "payment-method-mobile-pay-alert-message": "Brug MobilePay app'en på din telefon til at færdiggøre betalingen. Du skal godkende betalingen i MobilePay indenfor 5 minutter og vende tilbage hertil.",
        "payment-method-free": "Gratis",
        "payment-method-credit-card": "Kreditkort",
        "payment-method-one-click": "Betal med { $card }",
        "payment-method-mobile-pay": "MobilePay",
        "payment-method-salary-reduction": "Løntræk",
        "payment-method-lunch-registration": "Frokostordning",
        "payment-method-guest-lunch-registration": "Frokostordning",
        "payment-method-facilitynet-account": "{ -gopay-wallet } (<balance>{ $balance }</balance>)",
        "payment-method-invoice": "Faktura",
        "payment-method-card-expired": "udløbet",


        // --- Shopping basket ---

        // Basket section
        "shopping-basket-product-to-pay-by-free": "(gratis)",
        "shopping-basket-product-to-pay-by-credit-card": "(via\u00A0kort)",
        "shopping-basket-product-to-pay-by-one-click-payment": "(via\u00A0kort)",
        "shopping-basket-product-to-pay-by-mobile-pay": "(via\u00A0MobilePay)",
        "shopping-basket-product-to-pay-by-payroll-deduction": "(via\u00A0løntræk)",
        "shopping-basket-product-to-pay-by-lunch-registration": "(via\u00A0frokostordning)",
        "shopping-basket-product-to-pay-by-guest-lunch-registration": "(via\u00A0frokostordning)",
        "shopping-basket-product-to-pay-by-gopay-account": "(via\u00A0{ -gopay-wallet })",
        "shopping-basket-product-to-pay-by-invoice": "(via\u00A0faktura)",

        // Section total
        "shopping-basket-total-section-header": "Til betaling:",
        "shopping-basket-total-line": "\n .label = Til betaling",
        "shopping-basket-total-private-price": "Din betaling: { $privateTotal }",
        "shopping-basket-total-company-price": "Firmabetaling: { $companyTotal }",

        "shopping-basket-total-to-pay-by-free": "\n .label = Gratis",
        "shopping-basket-total-to-pay-by-credit-card": "\n .label = Med kort",
        "shopping-basket-total-to-pay-by-one-click-payment": "\n .label = Med kort",
        "shopping-basket-total-to-pay-by-mobile-pay": "\n .label = Med MobilePay",
        "shopping-basket-total-to-pay-by-payroll-deduction": "\n .label = Via løntræk",
        "shopping-basket-total-to-pay-by-lunch-registration": "\n .label = Via frokostordning",
        "shopping-basket-total-to-pay-by-guest-lunch-registration": "\n .label = Via frokostordning",
        "shopping-basket-total-to-pay-by-gopay-account": "\n .label = Med { -gopay-wallet }",
        "shopping-basket-total-to-pay-by-invoice": "\n .label = Med faktura",

        // Accounting section
        "shopping-basket-accounting-header": "\n .description = Kontering",

        // Conditions section
        "shopping-basket-conditions-terms-label": "Jeg accepterer <conditionsLink>handelsbetingelserne</conditionsLink>",
        "shopping-basket-conditions-terms-not-accepted": "Du skal acceptere handelsbetingelserne",


        // Refill account
        "-refill-account-balance": "{ $pos -> \n [initial] Saldo \n *[other] saldo \n }",
        "refill-account-balance-limit": "Din { -refill-account-balance } må aldrig overstige { $maxBalance }",

        "refill-title": "\n .primaryTopBarTitle = { -gopay-wallet }",
        "refill-tab": "\n .label = Tank op",
        "refill-current-balance": "Nuværende { -refill-account-balance }: { $currentBalanceFormatted }",
        "refill-step-description-1": "\n    .description = Indtast beløb",
        "refill-step-description-1-custom": "Andet beløb:",
        "refill-step-amount-custom-amount": "\n .label = Beløb ({ $currencySymbol })\n .helperText = Ny { -refill-account-balance } må ikke overstige { $maxBalance }",
        "refill-menu-item-custom-amount": "Andet",
        "refill-terms": "{ -payment-accept-terms }",
        "refill-account-perform-refill-button": "Gennemfør optankning ({ $toPay })\n .loadingLabel = Tanker penge op...",


        // Automatic account refill
        "automatic-refill-page-title": "\n .primaryTopBarTitle = Automatisk optankning",
        "automatic-refill-tab": "\n .label = Automatisk optankning",
        "automatic-refill-description": "Aktiver automatisk optankning, når din { -refill-account-balance } runder et bestemt beløb. Din { -refill-account-balance } må aldrig overstige { $maxBalance }",
        "automatic-refill-step-description-1": "\n    .description = Tank op med",
        "automatic-refill-step-when-balance-below": "\n .description = Når saldo er under",
        "automatic-refill-step-when-balance-below-input-amount": "\n    .label = Beløb ({ $currencySymbol })",
        "automatic-refill-confirm-button": "Aktiver automatisk tank-op\n .loadingLabel = Aktiverer...",
        "automatic-refill-confirm-button-activated": "Opdater\n .loadlingLabel = Opdaterer...",
        "automatic-refill-account": "Opdater automatisk optankning",
        "automatic-refill-cancel": "Afmeld automatisk optankning",
        "automatic-refill-cancel-modal": "\n    .description = Afmeld automatisk optankning?\n .loadingDescription = Afmelder...\n  .confirmLabel = Afmeld",
        "automatic-refill-activated-chip": "\n  .label = Aktiv",
        "automatic-refill-limit-amount-too-low": "Saldo genoptankningsgrænse må ikke være mindre end { $minLimitAmount }",
        "automatic-refill-would-exceed-max-balance": "Ny { -refill-account-balance } må ikke overstige { $maxBalance }",

        // Top-up
        "automatic-refill-no-subscription-banner-text": "Aktiver automatisk optankning",
        "automatic-refill-no-subscription-help-link": "Hvordan fungerer det?",
        "automatic-refill-no-subscription-help-drawer": "Ved at aktivere automatisk optankning har du altid penge i din digitale wallet.\n\n Du vælger optankningsbeløb og en beløbsgrænse, så tanker systemet automatisk penge på din wallet, når saldoen falder under grænseværdien.\n\n Når du aktiverer automatisk optankning, gemmes dit betalingskort sikkert hos vores certificerede betalingsgateway.\n .title = Sådan fungerer automatisk optankning",
        "automatic-refill-active-subscription-banner-text": "Auto tank-op er aktiv",
        "automatic-refill-active-subscription-banner-below": "Når saldo er under {$sum}",
        "automatic-refill-active-subscription-banner-amount": "Tank op med {$amount}",

        // Lunch subscription
        "lunch-subscription-page-title": "\n .primaryTopBarTitle = Mine abonnementer",
        "my-lunch-subscriptions-no-active-subscriptions": "Du har ingen aktive abonnementer.",
        "lunch-subscription-subscribe-button": "Tilmeld nyt abonnement",
        "lunch-subscription-subscribe-page-title": "\n .primaryTopBarTitle = Vælg abonnement",
        "lunch-subscription-subscribe-no-offers-message": "Der er ingen tilgængelige abonnementer.",
        "lunch-subscription-choose-offer": "Vælg",
        "lunch-subscription-unsubscribe-screen": "\n .primaryTopBarTitle = Afmeld abonnement",
        "lunch-subscription-unsubscribe-title": "Er du sikker på, at du vil afmelde dit abonnement?",
        "lunch-subscription-unsubscribe-terms": "Læs handelsbetingelser",

        "lunch-subscription-menu-cancel-subscription-option": "Afmeld abonnement",
        "lunch-subscription-subscription-conditions-modal": "\n .title = Abonnements\u00ADbetingelser",

        "lunch-subscription-basket": "\n .primaryTopBarTitle = Tilføj abonnement",
        "lunch-subscription-basket-step-subperiod-label": "Opstart",
        "lunch-subscription-basket-step-payment-label": "Betaling",
        "lunch-subscription-basket-period": "\n .description = Vælg abonnementsstart",
        "lunch-subscription-basket-period-too-late-notice": "(for sent)",
        "lunch-subscription-basket-period-next-button": "Næste",
        "lunch-subscription-basket-step-two": "\n .description = Diæt",
        "lunch-subscription-basket-step-three": "\n .description = Betaling",
        "lunch-subscription-basket-diet-vegetarian": "Vegetar",
        "lunch-subscription-terms": "{ -payment-accept-terms }",
        "lunch-subscription-payment-method-subscribe-payment-card": "Tilmeld nyt betalingskort",
        "lunch-subscription-payment-method-one-click-payment": "Betal med { $card }",
        "lunch-subscription-payment-method-payroll-deduction": "Løntræk",

        "lunch-subscription-price-per-month": "{$amount} pr. måned",

        "lunch-subscription-order": "Tilmeld\n .loadingLabel = Tilmelder...",
        "lunch-subscription-cancel": "Afmeld abonnement",
        "lunch-subscription-cancel-button": "Afmeld\n .loadingLabel = Afmelder...",
        "lunch-subscription-closed": "Lukket",
        "lunch-subscription-tab-month": "\n .label = Måned",
        "lunch-subscription-tab-week": "\n .label = Uge",
        "lunch-subscription-tab-day": "\n .label = Dag",
        "lunch-subscription-monthly-overview-description-closed": "Køkkenet er lukket",
        "lunch-subscription-monthly-overview-description-subscribed": "Tilmeldte dage",
        "lunch-subscription-on-locations": "\n .primaryTopBarTitle = Abonnement lokationer",
        "lunch-subscription-locations": "{$amount} lokationer",
        "lunch-subscription-conditions": "Abonnementsbetingelser",
        "lunch-subscription-start-date": "Starter {$date}",

        "lunch-subscription-weekly-overview": "Ugeoversigt",
        "lunch-subscription-weekly-overview-dish-of-the-day": "Dagens ret",
        "lunch-subscription-weekly-overview-description": "Fravælg de dage, du ikke ønsker at bestille frokost.",


        // New Order
        "order-cart-cleared": "Indkøbskurven blev tømt.",


        // Paymentcards
        "paymentcards-title": "\n .primaryTopBarTitle = Betalingskort",
        "paymentcards-card": "\n    .primary = {$number} ({$type})",
        "paymentcards-empty": "Du har ingen tilmeldte betalingskort. Hvis du gemmer et kort her, bliver det nemmere at betale, fordi du ikke skal taste dine kreditkortoplysninger hver gang.",
        "paymentcards-too-many-cards": "\n  .text = Du kan have { $maxPaymentCards} tilmeldte betalingskort. Fjern et kort for at kunne tilføje et nyt.",
        "paymentcards-button-add-payment-card": "Tilføj betalingskort\n .loadingLabel = Tilføjer kort...",
        "paymentcards-remove-card": "Fjern betalingskort",
        "paymentcards-options-dialog": "\n .loadingDescription = Fjerner kort...",


        // QuickPay
        "quickpay-generic-success": "Din betaling blev gennemført",
        "quickpay-finalization-error-timeout": "Vi har endnu ikke modtaget en bekræftelse fra din betalingsudbyder. Venligst tjek din netbank for at sikre, at betalingen er gennemført.",
        "quickpay-finalization-error-other": "Betalingen kunne ikke gennemføres på grund af en uventet fejl. Prøv venligst at gennemføre en ny betaling senere.",
        "quickpay-source-mismatch-title-should-be-app": "Handling fra app",
        "quickpay-source-mismatch-title-should-be-web": "Handling fra browser",
        "quickpay-source-mismatch-message-should-be-app": "Det lykkedes ikke automatisk at skifte tilbage til din GoPay app. Du er nødt til selv at gå tilbage til appen.",
        "quickpay-source-mismatch-message-should-be-web": "Dette er din GoPay app. Du brugte GoPay i din browser, men har appen installeret. Dette giver konflikt ved kreditkort og MobilePay transaktioner. Brug fortrinsvis appen, når den er installeret.",
        "quickpay-canceled": "\n .title = Køb annulleret\n .message = Du annullerede betalingen af dit køb",


        // Me page
        "me-user-greeting": "Hej {$firstName}",
        "me-my-wallets-section": "\n .title = Mine wallets",
        "me-my-wallets-status-auto-refill-active": "Auto tank-op",
        "me-my-wallets-wallet-name": "Min wallet",
        "me-my-wallets-wallet-brand": "GoPay Wallet",
        "me-my-facility-net": "Mit { -gopay-app-name }",
        "me-my-meetings": "Mine møder",
        "me-my-orders": "Mine ordrer",
        "me-customer-service-category": "Kundeservice",
        "me-customer-service-intercom-consent-dialog": "\n .title = Tillad chat\n .message = Vi benytter Intercom til vores chat support. Hvis du godkender, deler vi nedenstående oplysninger med Intercom.\n .skipLabel = Ikke nu\n .consentLabel = Tillad",
        "me-customer-service-intercom-error-dialog": "\n .title = Chat fejlede\n .message = Der opstod en fejl under indlæsning af chatten. Du kan i stedet sende en e-mail til support.\n .buttonLabel = Forstået",
        "me-customer-service-intercom-loading-dialog": "\n .description = Chatten indlæser...",
        "me-help-center": "Hjælpecenter",
        "me-chat-support": "Chat",
        "me-email-support": "E-mail support",
        "me-shortcuts": "Genveje",
        "me-payment-cards": "Betalingskort",
        "me-lunch-subscription-menu-item": "Abonnement",
        "me-settings": "Indstillinger",
        "me-logout": "Log ud",

        // User account page
        "user-account-page": "\n .primaryTopBarTitle = Brugerkonto",
        "user-account-type-company-account": "\n .label = Kontotype\n .value = Firmakonto",
        "user-account-type-private-account": "\n .label = Kontotype\n .value = Privat konto",
        "user-account-company": "\n  .label = Firma",
        "user-account-username": "\n  .label = Brugernavn",
        "user-account-name": "\n  .label = Navn",
        "user-account-email": "\n  .label = Email",
        "user-account-mobile": "\n  .label = Mobilnr",
        "user-account-department": "\n  .label = Afdeling",
        "user-account-about-link": "Om denne konto",
        "user-account-about-drawer-company-account": "### Firmakonto\n\n Firmaer og andre organisationer kan udbyde forskellige services med betaling via GoPay, f.eks. køb af kantinemad. Med din firmakonto kan du tilgå disse shops.\n .title = Om din brugerkonto",
        "user-account-about-drawer-private-account": "### Privat konto\n\n Udover firma shops findes der også offentlige shops i GoPay, som privat\u00ADpersoner kan købe i. Med din private konto har du adgang til alle offentlige shops.\n .title = Om din brugerkonto",


        // Me settings module
        "settings-title": "\n .primaryTopBarTitle = Indstillinger",
        "settings-change-language": "Skift sprog",
        "settings-english": "Engelsk",
        "settings-danish": "Dansk",
        "settings-push-notifications": "Push notifikationer",
        "settings-receipts-via-email": "Kvittering på e-mail",
        "settings-sms-notifications": "SMS notifikationer",
        "settings-customer-service": "Kundeservice",
        "settings-social-media": "Sociale medier",
        "settings-policies": "Politikker",
        "settings-HELP_CENTER": "Hjælpecenter",
        "settings-HELP_CENTER_USER_GUIDE_MOBILE_APP": "Brugerguide",
        "settings-SOCIAL_FACILITYNET_FACEBOOK": "Følg os på Facebook",
        "settings-SOCIAL_FACILITYNET_LINKEDIN": "Følg os på LinkedIn",
        "settings-PRIVACY_TERMS": "Privatlivspolitik",
        "settings-COOKIES_TERMS": "Cookie-betingelser",
        "settings-EULA": "EULA",


        // Fast track menu
        "fast-track-menu-show-webshop": "\n .title = Fast Track\n .contentActionText = Vis webshop",
        "fast-track-menu-no-menus": "Ingen menuer tilgængelige. Kontakt kantinen.",


        // Anonymous enter kitchen
        "anonymous-enter-kitchen-header": "Indtast shop nummer",
        "anonymous-enter-kitchen-shop-number-input": "\n .label = Shop nummer",
        "anonymous-enter-kitchen-submit-button": "{ -authenticate-submit-button }",
        "anonymous-enter-kitchen-loading-dialog": "\n .description = Indlæser Fast Track menu...",
        "anonymous-enter-kitchen-shop-number-requirements": "Indtast venligst et shop nummer på { $minDigits }-{ $maxDigits} cifre",


        // To fast track menu via route
        "to-fast-track-menu-via-route-invalid-kitchen-id": "Shop nummeret {$kitchenCode} findes ikke.",
        "to-fast-track-menu-via-route-error": "Noget gik galt",
        "to-fast-track-menu-via-route-contact": "Kontakt køkkenpersonalet hvis fejlen gentager sig.",


        // Choose location
        "choose-location-header-company-user": "Vælg din lokation",
        "choose-location-header-private-user": "Vælg salgssted",
        "choose-location-shop-number-field": "\n .label = Indtast shop nummer",
        "choose-location-shop-number-submit-button": "Vælg\n .loadingLabel = Indlæser...",
        "choose-location-about-shop-number-link": "Hvad er et shop nummer?",
        "choose-location-alternative-method-header": "⸺ eller ⸺",
        "choose-location-search-for-location-button": "Vis alle salgssteder",
        "choose-location-search-field": "\n .label = Søg på navn, adresse, shop nummer...",
        "choose-location-help-text": "Findes din lokation ikke skal du kontakte jeres administrator.",
        "choose-location-dialog": "\n .title = Skift lokation",
        "choose-location-dialog-private-user": "\n .title = Skift salgssted",
        "choose-location-shop-numbers": "Shop numre:",
        "choose-location-select-location-button": "Vælg\n .disabledLabel = Aktiv\n .loadingLabel = Gemmer valg...",


        // Order takeaway
        "order-takeaway-page-title": "\n .primaryTopBarTitle = Takeaway",

        // Order catering
        "order-catering-page-title": "\n .primaryTopBarTitle = Frokost",


        // Order takeaway/catering
        //"webshop-canteen-not-found-dialog": "\n .title = Ikke fundet\n .message = Køkkenet blev ikke fundet. Kontakt support.\n .buttonLabel = Luk",
        "order-basket-title": "\n .primaryTopBarTitle = Kurv",
        "webshop-shop-type-lunch": "Frokostordning",
        "webshop-shop-type-take-away": "Takeaway",
        "webshop-shop-type-catering": "Mødeforplejning",
        "order-food-add": "Tilføj",
        "order-food-amount-private": "Til mig",
        "order-food-amount-company": "Til gæst",
        "order-food-pickup": "Afhentes: {$time} {$place}",
        "order-food-items-left": "{$amount} tilbage",
        "order-food-basket-button": "Gå til kasse ({ $items }) { $price }",
        "order-food-item-out-of-stock": "Varen er udsolgt",
        "order-food-item-past-deadline": "Deadline er overskredet",
        "order-food-item-quick-add": "Tilføj",
        "order-food-basket-overview": "\n .description = Varekøb",
        "order-food-basket-private": "Privat",
        "order-food-basket-company": "Firma",
        "order-food-basket-guest": "Gæst:",
        "order-food-basket-guest-information": "\n .description = Gæsteinformation",
        "order-food-basket-payment": "\n .description = Betalingsmetode",
        "order-food-basket-payment-methods": "\n .title = Betalingsmetoder",
        "order-food-basket-delivery": "\n .description = Levering",
        "order-food-basket-pickup": "Skal afhentes",
        "order-food-basket-paid": "Betalt - {$selectedOrder} af {$maxOrders}",
        "order-food-basket-name": "\n .label = Dit navn\n .emptyErrorText = Dette felt skal udfyldes",
        "order-food-basket-email": "\n .label = Din e-mail\n .emptyErrorText = Dette felt skal udfyldes\n .invalidErrorText = E-mailen er ugyldig",
        "order-food-basket-phone": "\n .label = Telefonnr. (valgfrit)",
        "order-food-basket-comment": "\n .label = Skriv en kommentar",
        "order-food-basket-purchase-button": "Gennemfør betaling\n .loadingLabel = Gennemfører køb...",
        "order-food-basket-seller":"Sælger: {$seller}",
        "order-food-canteen-details-contact": "Kontakt",
        "order-food-canteen-details-address": "Adresse",
        "order-food-canteen-details-closed": "Lukket",
        "order-food-receipt-scan-to-checkin": "Scan for at tjekke ind",
        "order-food-receipt-scan-to-checkin-offline": "QR-koden kunne ikke indlæses.",
        "order-food-featured-deliverydate": "Vælg leveringstid",
        "order-food-upcoming-menus": "Kommende menuer",
        "order-food-empty-state-text": "Køkkenet tilbyder ingen mad idag. Prøv en af de andre dage.",
        "order-food-sold-out-snackbar": "Varen er udsolgt",

        "product-unavailable-product-not-found-modal": "\n .title = Vare ikke fundet\n .message = Den ønskede vare findes ikke i sortimentet for valgt dato. Kontakt kantinen.\n .buttonLabel = Forstået",
        "product-unavailable-product-unavailable-modal": "\n .buttonLabel = Forstået",


        // Product modal
        "canteenProductModal-preparation-label": "Tilberedning",
        "canteenProductModal-declaration-label": "Varedeklaration",
        "canteenProductModal-allergens-label": "Allergener",


        // Proof of Purchase
        "proof-of-purchase-order-id": "Ordre-id: { NUMBER($orderId, useGrouping: 0) }",
        "proof-of-purchase-open-receipt-button": "Se kvittering",
        "proof-of-purchase-order-type-lunch": "{ -order-type-lunch }",
        "proof-of-purchase-order-type-takeaway": "{ -order-type-take-away }",
        "proof-of-purchase-order-type-catering": "{ -order-type-catering }",
        "proof-of-purchase-order-type-meeting": "{ -order-type-meeting }",
        "proof-of-purchase-order-type-refill-account": "{ -order-type-refill-account }",
        "proof-of-purchase-order-type-auto-refill-account": "{ -order-type-auto-refill-account }",
        "proof-of-purchase-order-type-lunch-subscription": "{ -order-type-lunch-subscription }",
        "proof-of-purchase-order-type-subscribe-payment-card": "{ -order-type-subscribe-payment-card }",
        "proof-of-purchase-order-type-refund": "{ -order-type-refund }",
        "receipt-qr-dialog-back": "Tilbage",
        "receipt-no-deliveries": "Ordren indeholder ingen leveringer",
        "receipt-company-orderlines": "Firmakøb",

        "receipt-product-paid-by-free": "(gratis)",
        "receipt-product-paid-by-credit-card": "(via\u00A0kort)",
        "receipt-product-paid-by-one-click-payment": "(via\u00A0kort)",
        "receipt-product-paid-by-mobile-pay": "(via\u00A0MobilePay)",
        "receipt-product-paid-by-payroll-deduction": "(via\u00A0løntræk)",
        "receipt-product-paid-by-lunch-registration": "(via\u00A0frokostordning)",
        "receipt-product-paid-by-guest-lunch-registration": "(via\u00A0frokostordning)",
        "receipt-product-paid-by-gopay-account": "(via\u00A0{ -gopay-wallet })",
        "receipt-product-paid-by-invoice": "(via\u00A0faktura)",


        // Receipt
        "receipt-title": "Kvittering",
        "receipt-forward-receipt-dialog": "\n .title = { $receipts -> \n *[one] Send kvittering \n [other] Send kvitteringer \n } \n .cancelButtonLabel = Annuller\n .submitButtonLabel = Send\n .submitButtonLoadingLabel = Sender...",
        "receipt-forward-receipt-email-field": "\n .label = E-mail adresse",
        "receipt-forward-receipt-email-field-is-required": "E-mail adresse skal udfyldes",
        "receipt-forward-receipt-email-field-must-be-valid": "Ugyldig e-mail adresse",

        // Purchase section
        "receipt-purchase-details-section": "\n .heading = Købsdetaljer",
        "receipt-purchase-payment-status-paid": "BETALT",
        "receipt-purchase-payment-status-unpaid": "EJ BETALT",
        "receipt-purchase-payment-status-invoiced": "FAKTURERET",
        "receipt-purchase-payment-status-refunded": "REFUNDERET",
        "receipt-purchase-payment-status-cancelled": "REFUNDERET",
        "receipt-purchase-address-item": "\n .label = Købssted:",
        "receipt-purchase-seller-vat-number": "CVR:\u00A0{ $vatNumber }",
        "receipt-purchase-customer-item": "\n .label = Kunde:",
        "receipt-purchase-time-item": "\n .label = Købstidspunkt:",
        "receipt-purchase-payment-method-item": "\n .label = Betalingsmetode:",
        "receipt-purchase-order-id-item": "\n .label = Ordre-id:",
        "receipt-purchase-shop-channel-item": "\n .label = Via:",
        "receipt-purchase-cost-center-item": "\n .label = Kontostreng:",

        // Actions section
        "receipt-actions-forward-receipt-button": "Send kvittering",
        "receipt-actions-cancel-order-button": "Annuller bestilling",
        "receipt-actions-confirm-cancel-dialog": "\n .description = Du er ved at annullere din bestilling. Er du sikker?\n .confirmLabel = Ja\n .cancelLabel = Nej\n .loadingDescription = Annullerer bestilling...",
        "receipt-actions-cancel-unavailable-dialog": "\n .buttonLabel = Forstået",

        // Delivery section
        "receipt-delivery-details-section": "\n .heading = Levering",
        "receipt-delivery-order-type-item": "\n .label = Ordretype:",
        "receipt-order-type-lunch": "{ -order-type-lunch }",
        "receipt-order-type-takeaway": "{ -order-type-take-away }",
        "receipt-order-type-catering": "{ -order-type-catering }",
        "receipt-order-type-meeting": "{ -order-type-meeting }",
        "receipt-order-type-refill-account": "{ -order-type-refill-account }",
        "receipt-order-type-auto-refill-account": "{ -order-type-auto-refill-account }",
        "receipt-order-type-lunch-subscription": "{ -order-type-lunch-subscription }",
        "receipt-order-type-subscribe-payment-card": "{ -order-type-subscribe-payment-card }",
        "receipt-order-type-refund": "{ -order-type-refund }",
        "receipt-delivery-type-item": "\n .label = Leveringstype:",
        "receipt-delivery-type-pick-up": "Afhentning",
        "receipt-delivery-time-item": "\n .label = Leveringstid:",
        "receipt-delivery-comment-item": "\n .label = Kommentar:",

        // Order details section
        "receipt-payment-method-free": "Gratis",
        "receipt-payment-method-credit-card": "Kreditkort",
        "receipt-payment-method-one-click-payment": "Kreditkort",
        "receipt-payment-method-mobile-pay": "MobilePay",
        "receipt-payment-method-payroll-deduction": "Løntræk",
        "receipt-payment-method-lunch-registration": "Frokostordning",
        "receipt-payment-method-guest-lunch-registration": "Frokostordning",
        "receipt-payment-method-gopay-account": "{ -gopay-wallet }",
        "receipt-payment-method-invoice": "Faktura",

        "receipt-order-details-buyerparty-private": "Privatkøb",
        "receipt-order-details-buyerparty-company": "Firmakøb",
        "receipt-order-details-vat-total": "\n .label = Heraf moms:",

        // Accounting details section
        "receipt-accounting-details-section": "\n .heading = Kontering",


        // Order details
        "orderDetails-deliveryType-delivery": "\n .title = Levering",
        "orderDetails-deliveryType-pick-up": "\n .title = Afhentning",
        "orderDetails-deliveryType-eating-in-canteen": "\n .title = Spiser i kantinen",
        "orderDetails-attendees-subject-label" : "\n    .title = Mødedeltagere ({$count})",
        "orderDetails-note-label" : "\n    .title = Note",
        "orderDetails-catering-totalAmountIncludeVat-label" : "Beløb inkl. moms",
        "orderDetails-header-label" : "\n    .title = Mit møde",
        "orderDetails-meeting-privacyStatus-label" : "\n    .label = Privat",
        "orderDetails-resources-subject-label" : "Ressourcer",


        // Confirmation-dialog
        "confirmation-dialog-cancel": "Annuller",


        // Notifications
        "notifications-page-title": "\n .primaryTopBarTitle = Beskeder",
        "notifications-no-notifications-label" : "Ingen beskeder",


        // Room Finder
        "roomfinder-tabs-searcher": "Søger",
        "roomfinder-tabs-overview": "Oversigt",
        "roomfinder-overview-find-room": "\n    .label = Find lokale",
        // "roomFinder-availableResourceGroup-1": "Ledige rum",
        // "roomFinder-availableResourceGroup-2": "Ledige AV udstyr",
        // "roomFinder-availableResourceGroup-4": "Ledige biler",


        // Week menu
        "home-week-menu-title": "\n .title = Ugens menu",
        "weekMenu-badge": "Ugens menu",
        "weekMenu-header-label": "\n .title = Ugens menu",
        "weekMenu-no-menu-label": "Der er desværre ikke oprettet menuer i denne uge.",
        "weekMenu-no-menu-subtitle": "Her vil du kunne se menuen for de kommende dage, hvis den er tilgængelig. Du kan ikke foretage køb her.",


        // Basket
        "basket-removeAll-label" : "Fjern alle",


        // Meeting options
        "meetingOptions-extendMeeting-label": "Forlæng møde",
        "meetingOptions-endMeeting-label": "Afslut møde",
        "meetingOptions-viewDetails-label": "Vis detaljer",
        "meetingOptions-delete-label": "Aflys møde",
        "meetingOptions-edit-label": "Rediger møde",
        "meetingOptions-assistance-label": "Assistance",
        "-meetingOptions-startMeeting-base-label": "Start møde",
        "meetingOptions-startMeeting-expired-label": "{-meetingOptions-startMeeting-base-label} (udløbet)",
        "meetingOptions-startMeeting-left-label": "{-meetingOptions-startMeeting-base-label} ({$time}} tilbage)",
        "meetingOptions-startMeeting-in-label": "{-meetingOptions-startMeeting-base-label} (om {$time}})",


        // meetings
        "meetingStarted-labels": "\n    .topbarTitle = Møde påbegyndt\n    .title = Mødet er blevet påbegyndt\n    .buttonLabel = Fortsæt",
        "meetingEnded-labels": "\n    .topbarTitle = Møde afsluttet\n    .title = Mødet er blevet afsluttet\n    .buttonLabel = Fortsæt",
        "meetingDeleted-labels": "\n    .topbarTitle = Møde slettet\n    .title = Mødet er blevet slettet\n    .buttonLabel = Fortsæt",
        "meetingBooked-labels": "\n    .topbarTitle = Møde booket\n    .buttonLabel = Næste",
        "meetingUpdated-labels": "\n    .topbarTitle = Meeting Updated\n    .buttonLabel = Næste",
        "meeting-open-in-calendar": "Åbn i kalender",


        // News
        "allNews-header-label": "\n    .title = Nyheder",
        "allNews-noNews-label": "Ingen nyheder",
        "newsDetails-header-label": "\n    .title = Mine nyheder",
        "newsDetails-by-label": "Af {$creator} | {$company}",


        // Misc
        "action-sheet-cancel-button": "Annuller",
        "alert-dialog-close-button": "Luk",
        "options-novalue" : "-- Vælg --",
    },
    "en-US": {
        // Branding
        "-gopay-app-name": "GoPay",
        "-gopay-wallet": "GoPay Wallet",
        "-gopay-wallet-refill": "{ $pos -> \n [initial] { $count -> \n *[one] Top up \n [other] Top ups \n } \n *[other] { $count -> \n *[one] top up \n [other] top ups \n } \n }",


        // Preferred hour cycle for locale
        // NOTE: Only necessary to specify if default for country/region from CLDR supplementary data used by browser Intl API is incorrect
        // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/hourCycle
        // See: https://github.com/unicode-org/cldr/blob/master/common/supplemental/supplementalData.xml#L4768
        "date-time-hour-cycle": "h12",

        // Date and time formats as Luxon format strings
        // See: https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
        "date-time-format-date": "MM/dd/yyyy",
        "date-time-format-day-and-date": "EEEE MM/dd",
        "date-time-format-time": "hh:mm\u00A0a",
        "date-time-format-date-and-time": "MM/dd/yyyy\u00A0hh:mm\u00A0a",
        "date-time-format-duration-hours-minutes": "h'h.' m'min.'",
        "date-time-format-duration-minutes": "m'min.'",
        "date-time-format-duration-seconds": "s's'",
        "date-time-format-month-and-year": "MMMM, yyyy",


        // success / error handling
        "success-modal-status": "\n .buttonLabel = Close",
        "error-route-status": "\n .topbarTitle = Error",
        "error-dialog-close-button": "\n .buttonLabel = Close",
        "action-failed-modal": "\n .buttonLabel = Close",
        "errorHandler-labels": "\n    .title = Something went wrong\n    .buttonLabel = Click here to go to the front page",
        "errorHandlerModal-labels": "\n    .title = Something went wrong\n    .buttonLabel = Click here to close modal",
        "error-clientNetworkError-label" : "No Internet connection",
        "unknown-error-title": "Unknown Error",
        "unknown-error-message": "No additional information available",
        "general-error-title": "Something went wrong",
        "internal-error-title": "Internal Error",
        "api-call-error-title-network-error": "Network Error",
        "api-call-error-title-bad-request": "Bad Request",
        "api-call-error-title-unauthorized": "Invalid login",
        "api-call-error-title-forbidden": "Access Denied",
        "api-call-error-title-not-found": "Not Found",
        "api-call-error-title-conflict": "Conflict",
        "api-call-error-title-too-many-requests": "Too Many Requests",
        "api-call-error-title-internal-server-error": "Internal Server Error",
        "api-call-error-title-service-unavailable": "Service Unavailable",
        "api-call-error-title-request-failed": "Request Failed",
        "api-call-error-no-internet-connection": "No Internet connection",
        "api-call-error-could-not-reach-server": "Could not reach server",
        "api-call-error-protocol-error": "Server error: { $responseCode } { $responseStatus }",
        "api-call-error-api-returned-error": "Unexpected server error",
        "api-call-error-response-not-json": "Unexpected response from server",
        "api-call-error-retry-button": "Retry",


        // authenticate common texts
        "-authenticate-submit-button": "Next",


        // Order types shared translations
        "-order-type-lunch": "{ $count -> \n *[one] Daily purchase \n [other] Daily purchases \n }",
        "-order-type-take-away": "Takeaway",
        "-order-type-catering": "Meeting services",
        "-order-type-meeting": "{ $count -> \n *[one] Booking \n [other] Bookings \n }",
        "-order-type-lunch-subscription": "{ $count -> \n *[one] Subscription \n [other] Subscriptions \n }",
        "-order-type-subscribe-payment-card": "Subscribe payment card",
        "-order-type-refund": "{ $count -> \n *[one] Refund \n [other] Refunds \n }",


        // user prompts
        "user-prompt-button-label-saving": "\n .loadingLabel = Saving...",


        // welcome
        "authenticate-welcome-slide-header-buy-lunch": "Buy lunch and company services",
        "authenticate-welcome-slide-text-buy-lunch": "Easily purchase food from the lunch company and other company services such as catering at events and dry cleaning.",
        "authenticate-welcome-slide-header-reduce-food-waste": "Fight food waste",
        "authenticate-welcome-slide-text-reduce-food-waste": "Pre-order and cancel your lunch so the kitchen can plan food production. Select featured menus that are better for the environment.",
        "authenticate-welcome-slide-header-receive-offers": "This week's menus and deals",
        "authenticate-welcome-slide-text-receive-offers": "View the week's menus with nutrition information, discover deals and read news.",
        "authenticate-welcome-slide-header-purchase-overview": "View your purchases",
        "authenticate-welcome-slide-text-purchase-overview": "Get a good overview of your order history and receipts. If you pay by payroll deduction you can easily reconcile.",
        "authenticate-welcome-continue-button": "Login / Create Account",


        // login choices
        "authenticate-login-choices-header": "Order food and other services",
        "authenticate-login-choices-login-button": "Login",
        "authenticate-login-choices-create-account-button": "Create Account",
        "authenticate-login-choices-guest-link": "Buy as guest",


        // login / signup email
        "authenticate-email-header": "Enter your email address",
        "authenticate-email-signup-header": "Sign Up",
        "authenticate-email-email-input": "\n    .label = Email address",
        "authenticate-email-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Checking email address...",
        "authenticate-email-email-is-required": "Input your email address",
        "authenticate-email-email-must-be-an-email": "Invalid email",
        "authenticate-email-expect-company-flow": "You are attempting to sign up with the email domain <emailDomain>{ $emailDomain }</emailDomain> which is associated with a company. If you want to create a private account, you should use your private email address.",
        "authenticate-email-expect-private-flow": "Your email domain <emailDomain>{ $emailDomain }</emailDomain> is not currently associated with any company in the system. You must use your work email and not your private email address. Contact your administrator, if you believe your domain should be associated with a company.",
        "authenticate-email-switch-to-login-flow": "An account already exists with this email address. Do you wish to login instead?",
        "authenticate-email-switch-to-signup-flow": "No account found in the system with this email address. Do you wish to create one instead?",
        "authenticate-email-switch-flow-no-button": "No thanks",
        "authenticate-email-switch-flow-yes-button": "Yes please",
        "authenticate-email-okay-button": "Understood",


        // activate by phone
        "authenticate-phone-number-header": "Activate Account",
        "authenticate-phone-number-text": "We need your mobile number so we can send you an activation code:",
        "authenticate-phone-number-phone-number-input": "\n .label = Phone number",
        "authenticate-phone-number-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Sending code...",
        "authenticate-phone-number-username-missing": "Username / email is missing. Please go back and fill it in.",
        "authenticate-phone-number-phone-number-is-required": "Phone number is required",
        "authenticate-phone-number-phone-number-is-invalid": "Invalid phone number",


        // activation code
        "authenticate-activation-code-header": "Activate Account",
        "authenticate-activation-code-automatic-activation-text": "Please wait while we activate your account...",
        "authenticate-activation-code-manual-activation-text": "Please input the code you have received by email or text message:",
        "authenticate-activation-code-activation-code-input": "\n .label = Activation code",
        "authenticate-activation-code-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Activating...",
        "authenticate-activation-code-code-field-required": "Enter activation code",
        "authenticate-activation-code-code-field-min-len": "Activation code is min. { $codeLength } characters",


        // login/password
        "authenticate-password-header": "Enter your password",
        "authenticate-password-header-with-name": "Welcome { $displayName }! Please  sign in.",
        "authenticate-password-password-input": "\n .label = Password",
        "authenticate-password-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Signing in...",
        "authenticate-password-username-missing": "Username / email missing. Please go back and fill it in.",
        "authenticate-password-forgot-password-link": "Forgot password?",
        "authenticate-password-password-is-required": "Enter your password",


        // login/single sign on
        "authenticate-sso-header": "Single Sign-on",
        "authenticate-sso-explanation": "Your organization { $organization } requires single sign-on. You will now be sent to your organization login portal.",
        "authenticate-sso-canceled-message": "You did not complete the login process.",
        "authenticate-sso-retry-button": "Retry\n .loadingLabel = Redirecting...",


        // login/account-type
        "authenticate-account-type-choose-account-type": "Choose account type",
        "authenticate-account-type-private-account": "Private account",
        "authenticate-account-type-company-account": "Company account",
        "authenticate-account-type-private-account-description": "A private account allows you to sign up and access public shops using your personal email. Please be aware that you won't be able to access company shops with this type of account.",
        "authenticate-account-type-company-account-description": "A company account gives you access to the shops that are connected with your company. You must use your work email to sign up.",
        "authenticate-account-type-help-text": "Do I need a private or a company account?",
        "authenticate-account-type-help-drawer": "### Company Account\n\n Companies and organizations can offer services for payment via GoPay, like purchasing canteen meals. To access these shops, you need a company account. We determine your company based on your email domain.\n\n ### Private Account\n\n Besides company shops, there are public shops in GoPay where individuals can make purchases. With a private account, you can access all public shops.\n   .title = Account type explanation",


        // select company
        "authenticate-select-company-header-reason-multiple-companies-for-domain": "Choose company",
        "authenticate-select-company-header-reason-multiple-accounts-for-username": "Select user account",
        "authenticate-select-company-reason-message-multiple-companies-for-domain": "We were unable to determine your company automatically since your email domain <duplicateValue>{ $duplicateValue }</duplicateValue> occurs multiple times in our system.",
        "authenticate-select-company-reason-message-multiple-accounts-for-username": "We found multiple accounts in our system for the email address <duplicateValue>{ $duplicateValue }</duplicateValue>.",
        "authenticate-select-company-action-message-multiple-companies-for-domain": "Please choose the location (company) that you wish to create your account under:",
        "authenticate-select-company-action-message-multiple-accounts-for-username": "Please select the user account you want to log in with.",
        "authenticate-select-company-show-list-button": "Show companies",
        "authenticate-select-company-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Saving...",


        // sign up
        "authenticate-signup-header" : "Create Account",
        "authenticate-signup-text" : "We need to know a bit more about you to create your account:",
        "authenticate-signup-name-input": "\n .label = Name",
        "authenticate-signup-mobile-input": "\n .label = Mobile (optional)",
        "authenticate-signup-accept-terms-link": "I accept the terms and conditions",
        "authenticate-signup-submit-button": "{ -authenticate-submit-button }",
        "authenticate-signup-username-missing": "Username / email missing. Please go back and fill it in.",
        "authenticate-signup-name-is-required": "Name is required",
        "authenticate-signup-name-must-be-full-name": "We need your full name (given name and family name)",
        "authenticate-signup-phone-number-is-invalid": "Invalid phone number",
        "authenticate-signup-terms-must-be-accepted": "You must accept the terms to use the app",


        // confirm account details
        "authenticate-confirm-account-details-header": "Confirm Details",
        "authenticate-confirm-account-details-email-field": "\n .primary = Email",
        "authenticate-confirm-account-details-name-field": "\n .primary = Name",
        "authenticate-confirm-account-details-mobile-field": "\n .primary = Mobile",
        "authenticate-confirm-account-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Creating your account...",
        "authenticate-confirm-account-details-account-missing": "Account details missing. Please go back and fill them in.",


        // create password
        "authenticate-create-password-header": "Create Password",
        "authenticate-create-password-text": "You must create a password to use your new account:",
        "authenticate-create-password-password-input": "\n .label = Password",
        "authenticate-create-password-repeat-password-input": "\n .label = Repeat password",
        "authenticate-create-password-submit-button": "{ -authenticate-submit-button }\n .loadingLabel = Creating your password...",
        "authenticate-create-password-one-time-token-missing": "Could not find one time token. Please go a step back and retry.",
        "authenticate-create-password-password-is-required": "You must type a password",
        "authenticate-create-password-password-too-short": "Your password must be at least { $minPasswordLength } characters",
        "authenticate-create-password-repeat-is-required": "You must repeat your password",
        "authenticate-create-password-password-mismatch": "Your passwords do not match",


        // reset password
        "authenticate-reset-password-header": "Reset Password",
        "authenticate-reset-password-button": "Reset\n .loadingLabel = Resetting...",
        "authenticate-reset-password-username-missing": "Username / email missing. Please go back and fill it in.",


        // login route components
        "login-external-guest-dialog": "\n .title = Guest Purchase\n .message = You are about to purchase as guest. Shop will open in a new window.\n .buttonLabel = Continue",


        // --- User cards ---
        "user-cards-loading-element": "\n .title = Loading cards...",


        // home
        "-show-all": "Show all",
        "-home-upcoming-meetings": "Upcoming Meetings",
        "-home-offers": "Great Deals",
        "-home-webshops": "Webshops",
        "-home-my-favorites": "My Favorites",

        "home-label": "Home",

        "home-news": "\n    .title = News\n    .rightOptionText = {-show-all}",
        "home-news-kitchen": "\n    .title = News from the kitchen\n    .rightOptionText = { -show-all }",
        "home-news-company-and-service-center": "\n    .title = News from {$company}\n    .rightOptionText = { -show-all }",
        "home-news-facilitynet": "\n    .title = News from { -gopay-app-name }\n    .rightOptionText = { -show-all }",

        "home-my-meetings": "\n    .title = { -home-upcoming-meetings }\n    .rightOptionText = {-show-all}",
        "home-upcoming-meetings": "{ -home-upcoming-meetings }",
        "home-contact-the-canteen": "Contact the canteen",
        "home-no-planned-meetings": "You don't have any planned meetings",
        "home-plan-meeting": "Plan a meeting",

        "home-you-are-here": "You are here:",
        "home-change-location": "Change location",

        "home-takeaway-offers": "\n     .title = { -home-offers } \n     .rightOptionText = {-show-all}",
        "home-all-takeaway-offers-title": "\n   .primaryTopBarTitle = { -home-offers }",

        "home-takeaway-shops": "\n      .title = { -home-webshops } \n      .rightOptionText = {-show-all}",
        "home-all-takeaway-shops-title": "\n   .primaryTopBarTitle = { -home-webshops }",

        "home-product-favorites": "\n      .title = { -home-my-favorites } \n      .rightOptionText = { -show-all }",
        "home-all-product-favorites-title": "\n   .primaryTopBarTitle = { -home-my-favorites }",
        "home-all-product-favorites-empty": "You have no favorites.",
        "home-product-favorites-options": "\n .loadingDescription = Removing favorite...",
        "home-remove-favorite": "Remove favorite",

        "home-user-card-survey-rate-now": "Rate now",

        "home-user-card-check-in-reminder-check-in": "Thanks for checking in today",
        "home-user-card-check-in-reminder-no-check-in": "Please remember to check into the canteen today",
        "home-user-card-check-in-reminder-learn-more": "Learn more",

        "home-user-card-recent-purchase-view-receipt": "View receipt",
        "home-user-card-recent-purchase-status": "Status: { $status }",

        // user survey modal
        "user-survey-step2-question-good": "What were you especially happy with?",
        "user-survey-step2-question-bad": "What could we have done better?",
        "user-survey-add-comment-button": "\n {$comment ->\n      *[0] Write a\n      [other] Edit \n} comment",
        "user-survey-comment-drawer": "\n   .title = Comment",
        "user-survey-comment-field": "\n   .label = Leave your comment here",
        "user-survey-thank-you-message": "Thank you for your participation",
        "user-survey-next-button": "Next",
        "user-survey-skip-button": "Skip",
        "user-survey-submit-button": "Submit\n .loadingLabel = Sending...",
        "user-survey-close-button": "Close",
        "user-survey-comment-submit-button": "Save",
        "user-survey-comment-cancel-button": "Cancel",


        // navigation
        "bottom-navigation-home": "\n   .label = Home",
        "bottom-navigation-todays-purchases": "\n   .label = Today",
        "bottom-navigation-order": "\n   .label = Order",
        "bottom-navigation-messages": "\n   .label = Messages",
        "bottom-navigation-me": "\n   .label = Me",

        "app-navigation-to-webshop-select": "Go to Webshops",
        "app-navigation-to-login": "Go to Login",
        "app-navigation-to-home": "Go to Home",


        // Consent dialog
        "consent-dialog-personal-information-header": "Personal Information",
        "consent-dialog-pii-category-contact-info": "Contact Information",
        "consent-dialog-pii-category-identifiers": "Identifiers",
        "consent-dialog-pii-category-purchases": "Purchases",
        "consent-dialog-pii-category-user-content": "User Content",
        "consent-dialog-pii-item-name": "Name",
        "consent-dialog-pii-item-email-address": "Email",
        "consent-dialog-pii-item-phone-number": "Phone Number",
        "consent-dialog-pii-item-user-id": "User ID",
        "consent-dialog-pii-item-device-id": "Device ID",
        "consent-dialog-pii-item-customer-support": "Customer Service",
        "consent-dialog-pii-item-purchase-history": "Purchase History",
        "consent-dialog-privacy-policy-header": "Read our privacy policy terms",
        "consent-dialog-privacy-policy-link": "See company's privacy policy",


        // My Meetings
        "my-meetings-sittings": "{$amount} sittings",
        "my-meetings-header-label": "\n   .primaryTopBarTitle = My Meetings",
        "my-meetings-now-badge": "\n      .label = Now",
        "my-meetings-search-label": "\n   .label = Search",
        "my-meetings-no-filtered-label": "No matches",
        "my-meetings-no-meetings-label": "No planned meetings",


        // Meeting details
        "meeting-details-delete-dialog": "\n  .description = Delete delivery on {$date}?\n .loadingDescription = Deleting...\n  .confirmLabel = Delete",


        // Todays purchases
        "todays-orders-receipt-top-bar-title": "Todays Purchases",
        "todays-orders-receipt-top-bar-title-length": "Todays Purchase {$selectedOrder} of {$maxOrders}",
        "todays-orders-none-found": "You do not have purchases for today.",
        "todays-orders-no-such-order": "Order not found",


        // My Orders
        "my-orders-page": "\n  .primaryTopBarTitle = My Orders",
        "my-orders-page-header": "\n .title = My Orders",

        // My Orders tabs
        "my-orders-tab-purchases": "\n .label = Purchases",
        "my-orders-tab-wallet-refills": "\n .label = { -gopay-wallet-refill(pos: \"initial\", count: 100) }",

        // My Orders expenses chart tabs
        "my-orders-expenses-chart-tab-last-week": "\n .label = Last 7 days",
        "my-orders-expenses-chart-tab-last-month": "\n .label = Last 30 days",
        "my-orders-expenses-chart-tab-last-year": "\n .label = Last 365 days",
        "my-orders-expenses-chart-title": "Private purchases",

        // My Orders filter button
        "my-orders-filter-button-none": "None",
        "my-orders-filter-button-all": "All",
        "my-orders-filter-button-some": "{$selected} / {$available} selected",
        "my-orders-filter-button-lunch": "{ -order-type-lunch(count: 100) }",
        "my-orders-filter-button-take-away": "{ -order-type-take-away(count: 100) }",
        "my-orders-filter-button-catering": "{ -order-type-catering(count: 100) }",
        "my-orders-filter-button-meeting": "{ -order-type-meeting(count: 100) }",
        "my-orders-filter-button-refill-account": "{ -gopay-wallet-refill(pos: \"initial\", count: 100) }",
        "my-orders-filter-button-auto-refill-account": "Automatic { -gopay-wallet-refill(count: 100) }",
        "my-orders-filter-button-lunch-subscription": "{ -order-type-lunch-subscription(count: 100) }",
        "my-orders-filter-button-subscribe-payment-card": "{ -order-type-subscribe-payment-card(count: 100) }",
        "my-orders-filter-button-refund": "{ -order-type-refund(count: 100) }",

        // My Orders filter order type
        "my-orders-filter-order-type-lunch": "\n .label = { -order-type-lunch(count: 100) }",
        "my-orders-filter-order-type-take-away": "\n .label = { -order-type-take-away(count: 100) }",
        "my-orders-filter-order-type-catering": "\n .label = { -order-type-catering(count: 100) }",
        "my-orders-filter-order-type-meeting": "\n .label = { -order-type-meeting(count: 100) }",
        "my-orders-filter-order-type-refill-account": "\n .label = { -gopay-wallet-refill(pos: \"initial\", count: 100) }",
        "my-orders-filter-order-type-auto-refill-account": "\n .label = Automatic { -gopay-wallet-refill(count: 100) }",
        "my-orders-filter-order-type-lunch-subscription": "\n .label = { -order-type-lunch-subscription(count: 100) }",
        "my-orders-filter-order-type-subscribe-payment-card": "\n .label = { -order-type-subscribe-payment-card(count: 100) }",
        "my-orders-filter-order-type-refund": "\n .label = { -order-type-refund(count: 100) }",

        "my-orders-filter-modal": "\n .title = Order Types\n .cancelButtonLabel = Close",
        "my-orders-filter-order-type-label": "Order Types",
        "my-orders-results": "{$amount} results",
        "my-orders-load-more-button": "Load more",
        "my-orders-price-total": "Total",
        "my-orders-print": "Print",
        "my-orders-edit": "Edit",
        "my-orders-share": "Share",
        "my-orders-delete": "Delete",
        "my-orders-receipt": "Receipts",


        // Transactions
        "transaction-generic-processing": "Please wait...",
        "transaction-generic-success": "Transaction completed",


        // Webshop - payment methods
        "-payment-accept-terms": "I accept the terms and conditions",

        "payment-method-initial-message": "Choose payment method",
        "payment-method-mobile-pay-alert-message": "You will need to use the MobilePay app on your phone to complete the payment. You must approve the payment in MobilePay within 5 minutes and return here.",
        "payment-method-free": "Free",
        "payment-method-credit-card": "Credit card",
        "payment-method-mobile-pay": "MobilePay",
        "payment-method-one-click": "Pay using { $card }",
        "payment-method-salary-reduction": "Payroll deduction",
        "payment-method-lunch-registration": "Lunch registration",
        "payment-method-guest-lunch-registration": "Lunch registration",
        "payment-method-facilitynet-account": "{ -gopay-wallet } (<balance>{ $balance }</balance>)",
        "payment-method-invoice": "Invoice",
        "payment-method-card-expired": "expired",


        // --- Shopping basket ---

        // Basket section
        "shopping-basket-product-to-pay-by-free": "(for free)",
        "shopping-basket-product-to-pay-by-credit-card": "(by\u00A0card)",
        "shopping-basket-product-to-pay-by-one-click-payment": "(by\u00A0card)",
        "shopping-basket-product-to-pay-by-mobile-pay": "(by\u00A0MobilePay)",
        "shopping-basket-product-to-pay-by-payroll-deduction": "(by\u00A0payroll)",
        "shopping-basket-product-to-pay-by-lunch-registration": "(by\u00A0lunch\u00A0registration)",
        "shopping-basket-product-to-pay-by-guest-lunch-registration": "(by\u00A0lunch\u00A0registration)",
        "shopping-basket-product-to-pay-by-gopay-account": "(by\u00A0{ -gopay-wallet })",
        "shopping-basket-product-to-pay-by-invoice": "(by\u00A0invoice)",

        // Section total
        "shopping-basket-total-section-header": "To pay:",
        "shopping-basket-total-line": "\n .label = To pay",
        "shopping-basket-total-private-price": "Your payment: { $privateTotal }",
        "shopping-basket-total-company-price": "Company payment: { $companyTotal }",

        "shopping-basket-total-to-pay-by-free": "\n .label = Free",
        "shopping-basket-total-to-pay-by-credit-card": "\n .label = By card",
        "shopping-basket-total-to-pay-by-one-click-payment": "\n .label = By card",
        "shopping-basket-total-to-pay-by-mobile-pay": "\n .label = By MobilePay",
        "shopping-basket-total-to-pay-by-payroll-deduction": "\n .label = By payroll",
        "shopping-basket-total-to-pay-by-lunch-registration": "\n .label = By lunch registration",
        "shopping-basket-total-to-pay-by-guest-lunch-registration": "\n .label = By lunch registration",
        "shopping-basket-total-to-pay-by-gopay-account": "\n .label = By { -gopay-wallet }",
        "shopping-basket-total-to-pay-by-invoice": "\n .label = By invoice",

        // Accounting section
        "shopping-basket-accounting-header": "\n .description = Accounting",

        // Conditions section
        "shopping-basket-conditions-terms-label": "I accept the <conditionsLink>terms and conditions</conditionsLink>",
        "shopping-basket-conditions-terms-not-accepted": "You must accept the terms and conditions",


        // Refill account
        "-refill-account-balance": "{ $pos -> \n [initial] Balance \n *[other] balance \n }",
        "refill-account-balance-limit": "Your { -refill-account-balance } may not exceed { $maxBalance }",

        "refill-title": "\n .primaryTopBarTitle = { -gopay-wallet }",
        "refill-tab": "\n .label = { -gopay-wallet-refill(pos: \"initial\") }",
        "refill-current-balance": "Current { -refill-account-balance }: { $currentBalanceFormatted }",
        "refill-step-description-1": "\n    .description = Select amount",
        "refill-step-description-1-custom": "Other amount:",
        "refill-step-amount-custom-amount": "\n .label = Amount ({ $currencySymbol })\n .helperText = New { -refill-account-balance } cannot exceed { $maxBalance }",
        "refill-menu-item-custom-amount": "Other",
        "refill-terms": "{ -payment-accept-terms }",
        "refill-account-perform-refill-button": "Complete { -gopay-wallet-refill } ({ $toPay })\n .loadingLabel = Performing { -gopay-wallet-refill }...",


        // Automatic account refill
        "automatic-refill-page-title": "\n .primaryTopBarTitle = Automatic Top Up",
        "automatic-refill-tab": "\n .label = Automatic { -gopay-wallet-refill }",
        "automatic-refill-description": "Automatically { -gopay-wallet-refill } account when your { -refill-account-balance } falls below a certain amount. Your { -refill-account-balance } is never allowed to surpass { $maxBalance }",
        "automatic-refill-step-description-1": "\n    .description = { -gopay-wallet-refill(pos: \"initial\") } amount",
        "automatic-refill-step-when-balance-below": "\n .description = When balance below",
        "automatic-refill-step-when-balance-below-input-amount": "\n    .label = Amount ({ $currencySymbol })",
        "automatic-refill-confirm-button": "Activate automatic { -gopay-wallet-refill }\n .loadingLabel = Activating...",
        "automatic-refill-confirm-button-activated": "Update\n .loadingLabel = Updating...",
        "automatic-refill-account": "Update automatic { -gopay-wallet-refill }",
        "automatic-refill-cancel": "Deactivate automatic { -gopay-wallet-refill }",
        "automatic-refill-cancel-modal": "\n    .description = Deactivate automatic { -gopay-wallet-refill }?\n .loadingDescription = Deactivating...\n  .confirmLabel = Confirm",
        "automatic-refill-activated-chip": "\n  .label = Active",
        "automatic-refill-limit-amount-too-low": "Balance refill limit cannot be lower than { $minLimitAmount }",
        "automatic-refill-would-exceed-max-balance": "New { -refill-account-balance } cannot exceed { $maxBalance }",

        // Top-up
        "automatic-refill-no-subscription-banner-text": "Activate automatic top up",
        "automatic-refill-no-subscription-help-link": "How does it work?",
        "automatic-refill-no-subscription-help-drawer": "By activating automatic top up you will always have money in your digital wallet.\n\n You decide how much to top up and a threshold, then the system will top up your wallet whenever the balance falls below the threshold.\n\n When activating automatic top up your credit card will be saved securely at our certified payment gateway.\n .title = How auto top up works",
        "automatic-refill-active-subscription-banner-text": "Auto top up is active",
        "automatic-refill-active-subscription-banner-below": "When balance falls below {$sum}",
        "automatic-refill-active-subscription-banner-amount": "Top up with {$amount}",

        // Lunch subscription
        "lunch-subscription-page-title": "\n .primaryTopBarTitle = My Subscriptions",
        "my-lunch-subscriptions-no-active-subscriptions": "You have no active subscriptions.",
        "lunch-subscription-subscribe-button": "Add new subscription",
        "lunch-subscription-subscribe-page-title": "\n .primaryTopBarTitle = Choose Subscription",
        "lunch-subscription-subscribe-no-offers-message": "You have no available subscriptions.",
        "lunch-subscription-choose-offer": "Choose",
        "lunch-subscription-unsubscribe-screen": "\n .primaryTopBarTitle = Cancel subscription",
        "lunch-subscription-unsubscribe-title": "Are you sure you want to cancel your subscription?",
        "lunch-subscription-unsubscribe-terms": "Read terms and conditions",

        "lunch-subscription-menu-cancel-subscription-option": "Cancel subscription",
        "lunch-subscription-subscription-conditions-modal": "\n .title = Subscription Conditions",

        "lunch-subscription-basket": "\n .primaryTopBarTitle = Add subscription",
        "lunch-subscription-basket-step-subperiod-label": "Subscription period",
        "lunch-subscription-basket-step-payment-label": "Payment",
        "lunch-subscription-basket-period": "\n .description = Select subscription start",
        "lunch-subscription-basket-period-too-late-notice": "(too late)",
        "lunch-subscription-basket-period-next-button": "Next",
        "lunch-subscription-basket-step-two": "\n .description = Diet",
        "lunch-subscription-basket-step-three": "\n .description = Payment",
        "lunch-subscription-basket-diet-vegetarian": "Vegetarian",
        "lunch-subscription-terms": "{ -payment-accept-terms }",
        "lunch-subscription-payment-method-subscribe-payment-card": "Add new payment card",
        "lunch-subscription-payment-method-one-click-payment": "Pay using { $card }",
        "lunch-subscription-payment-method-payroll-deduction": "Payroll deduction",

        "lunch-subscription-price-per-month": "{ $amount } per month",

        "lunch-subscription-order": "Subscribe\n .loadingLabel = Subscribing...",
        "lunch-subscription-cancel": "Unsubscribe",
        "lunch-subscription-cancel-button": "Unsubscribe\n .loadingLabel = Unsubscribing...",
        "lunch-subscription-closed": "Closed",
        "lunch-subscription-tab-month": "\n .label = Month",
        "lunch-subscription-tab-week": "\n .label = Week",
        "lunch-subscription-tab-day": "\n .label = Day",
        "lunch-subscription-monthly-overview-description-closed": "Kitchen is closed",
        "lunch-subscription-monthly-overview-description-subscribed": "Subscribed days",
        "lunch-subscription-on-locations": "\n .primaryTopBarTitle = Subscription locations",
        "lunch-subscription-locations": "{$amount} locations",
        "lunch-subscription-conditions": "Subscription terms",
        "lunch-subscription-start-date": "Starts on {$date}",

        "lunch-subscription-weekly-overview": "Week Overview",
        "lunch-subscription-weekly-overview-dish-of-the-day": "Dish of the Day",
        "lunch-subscription-weekly-overview-description": "Unsubscribe the days you don't want to order lunch.",


        // New Order
        "order-cart-cleared": "Basket has been emptied.",


        // Payment cards
        "-payment-cards": "{ $pos -> \n [title] Payment Cards \n [initial] Payment cards \n *[other] payment cards \n [one] payment card \n }",
        "paymentcards-title": "\n .primaryTopBarTitle = { -payment-cards(pos: \"title\") }",
        "paymentcards-card": "\n    .primary = {$number} ({$type})",
        "paymentcards-empty": "You don't have any saved { -payment-cards } on file. If you save a card here, it'll make paying easier because you won't have to enter your credit card information each time.",
        "paymentcards-too-many-cards": "\n  .text = You can have up to { $maxPaymentCards } registered { -payment-cards }. Delete a card in order to add a new one.",
        "paymentcards-button-add-payment-card": "Add { -payment-cards(pos: \"one\") }\n .loadingLabel = Adding card...",
        "paymentcards-remove-card": "Remove { -payment-cards(pos: \"one\") }",
        "paymentcards-options-dialog": "\n .loadingDescription = Removing card...",


        // QuickPay
        "quickpay-generic-success": "Your payment succeeded",
        "quickpay-finalization-error-timeout": "We haven't received confirmation from your payment provider. Please check your netbank to be sure that the payment was completed.",
        "quickpay-finalization-error-other": "Payment could not be completed due to an unknown error. Please try again later.",
        "quickpay-source-mismatch-title-should-be-app": "Client mismatch",
        "quickpay-source-mismatch-title-should-be-web": "Client mismatch",
        "quickpay-source-mismatch-message-should-be-app": "If you see this message we could not switch back to your GoPay app automatically. Please switch back to the app manually.",
        "quickpay-source-mismatch-message-should-be-web": "This is your GoPay app. You used GoPay in your browser but have the app installed. This causes a conflict during credit card and MobilePay transactions. Prefer to use the app when it is installed.",
        "quickpay-canceled": "\n .title = Purchase canceled\n .message = You canceled the payment of your order.",


        // Me page
        "me-user-greeting": "Hi {$firstName}",
        "me-my-wallets-section": "\n .title = My Wallets",
        "me-my-wallets-status-auto-refill-active": "Auto top-up",
        "me-my-wallets-wallet-name": "My wallet",
        "me-my-wallets-wallet-brand": "GoPay Wallet",
        "me-my-facility-net": "My { -gopay-app-name }",
        "me-my-meetings": "My Meetings",
        "me-my-orders": "My Orders",
        "me-customer-service-category": "Customer Service",
        "me-customer-service-intercom-consent-dialog": "\n .title = Allow Chat\n .message = We use Intercom for our chat support. If you consent we will share the following information with Intercom.\n .skipLabel = Not now\n .consentLabel = Allow",
        "me-customer-service-intercom-error-dialog": "\n .title = Chat Failed\n .message = Something went wrong when loading the chat. You can send an email to support instead.\n .buttonLabel = Understood",
        "me-customer-service-intercom-loading-dialog": "\n .description = Chat is loading...",
        "me-help-center": "Help Center",
        "me-chat-support": "Chat",
        "me-email-support": "Email support",
        "me-shortcuts": "Shortcuts",
        "me-payment-cards": "{ -payment-cards(pos: \"title\") }",
        "me-lunch-subscription-menu-item": "Subscription",
        "me-settings": "Settings",
        "me-logout": "Logout",

        // User account page
        "user-account-page": "\n .primaryTopBarTitle = User Account",
        "user-account-type-company-account": "\n .label = Account type\n .value = Company account",
        "user-account-type-private-account": "\n .label = Account type\n .value = Private account",
        "user-account-company": "\n  .label = Company",
        "user-account-username": "\n  .label = Username",
        "user-account-name": "\n  .label = Name",
        "user-account-email": "\n  .label = Email",
        "user-account-mobile": "\n  .label = Mobile",
        "user-account-department": "\n  .label = Department",
        "user-account-about-link": "About this account",
        "user-account-about-drawer-company-account": "### Company Account\n\n Companies and organizations can offer services for payment via GoPay, like purchasing canteen meals. With your company account, you can access these shops.\n  .title = About your account",
        "user-account-about-drawer-private-account": "### Private Account\n\n Besides company shops, there are public shops in GoPay where individuals can make purchases. With your private account, you can access all public shops.\n  .title = About your account",


        // Me settings module
        "settings-title": "\n .primaryTopBarTitle = Settings",
        "settings-change-language": "Change language",
        "settings-english": "English",
        "settings-danish": "Danish",
        "settings-push-notifications": "Push notifications",
        "settings-receipts-via-email": "Receipts on email",
        "settings-sms-notifications": "Text (SMS) notifications",
        "settings-customer-service": "Customer Service",
        "settings-social-media": "Social Media",
        "settings-policies": "Policies",
        "settings-HELP_CENTER": "Help Center",
        "settings-HELP_CENTER_USER_GUIDE_MOBILE_APP": "User Guide",
        "settings-SOCIAL_FACILITYNET_FACEBOOK": "Follow us on Facebook",
        "settings-SOCIAL_FACILITYNET_LINKEDIN": "Follow us on LinkedIn",
        "settings-PRIVACY_TERMS": "Privacy Policy",
        "settings-COOKIES_TERMS": "Cookie Policy",
        "settings-EULA": "EULA",


        // Fast track menu
        "fast-track-menu-show-webshop": "\n .title = Fast Track\n .contentActionText = Go to shop",
        "fast-track-menu-no-menus": "No menus available. Contact kitchen.",


        // Anonymous enter kitchen
        "anonymous-enter-kitchen-header": "Enter shop number",
        "anonymous-enter-kitchen-shop-number-input": "\n .label = Shop number",
        "anonymous-enter-kitchen-submit-button": "{ -authenticate-submit-button }",
        "anonymous-enter-kitchen-loading-dialog": "\n .description = Loading Fast Track menu...",
        "anonymous-enter-kitchen-shop-number-requirements": "Please enter a shop number of { $minDigits }-{ $maxDigits} digits",


        // To fast track menu via route
        "to-fast-track-menu-via-route-invalid-kitchen-id": "The shop number {$kitchenCode} does not exist.",
        "to-fast-track-menu-via-route-error": "Something went wrong",
        "to-fast-track-menu-via-route-contact": "Contact kitchen staff if the error happens again.",


        // Choose location
        "choose-location-header-company-user": "Choose your location",
        "choose-location-header-private-user": "Choose sales location",
        "choose-location-shop-number-field": "\n .label = Enter shop number",
        "choose-location-shop-number-submit-button": "Confirm\n .loadingLabel = Loading...",
        "choose-location-about-shop-number-link": "What is a shop number?",
        "choose-location-alternative-method-header": "⸺ or ⸺",
        "choose-location-search-for-location-button": "Show all sales locations",
        "choose-location-search-field": "\n .label = Search on name, address, shop number...",
        "choose-location-help-text": "Contact your administrator if your location is not on the list.",
        "choose-location-dialog": "\n .title = Change Location",
        "choose-location-dialog-private-user": "\n .title = Change Sales Location",
        "choose-location-shop-numbers": "Shop numbers:",
        "choose-location-select-location-button": "Select\n .disabledLabel = Active\n .loadingLabel = Saving choice...",


        // Order takeaway
        "order-takeaway-page-title": "\n .primaryTopBarTitle = Takeaway",

        // Order catering
        "order-catering-page-title": "\n .primaryTopBarTitle = Lunch",

        // Order takeaway/catering
        //"webshop-canteen-not-found-dialog": "\n .title = Not Found\n .message = Kitchen not found. Please contact support.\n .buttonLabel = Close",
        "order-basket-title": "\n .primaryTopBarTitle = Basket",
        "webshop-shop-type-lunch": "Lunch",
        "webshop-shop-type-take-away": "Takeaway",
        "webshop-shop-type-catering": "Meeting services",
        "order-food-add": "Add",
        "order-food-amount-private": "For me",
        "order-food-amount-company": "For guest",
        "order-food-pickup": "Pickup: {$time} {$place}",
        "order-food-items-left": "{$amount} left",
        "order-food-basket-button": "Checkout ({ $items }) { $price }",
        "order-food-item-out-of-stock": "Out of stock",
        "order-food-item-past-deadline": "Past deadline",
        "order-food-item-quick-add": "Add",
        "order-food-basket-overview": "\n .description = Items",
        "order-food-basket-private": "Private",
        "order-food-basket-company": "Company",
        "order-food-basket-guest": "Guest:",
        "order-food-basket-guest-information": "\n .description = Guest information",
        "order-food-basket-payment": "\n .description = Payment method",
        "order-food-basket-payment-methods": "\n .title = Payment Methods",
        "order-food-basket-delivery": "\n .description = Delivery",
        "order-food-basket-pickup": "For pickup",
        "order-food-basket-paid": "Paid - {$selectedOrder} of {$maxOrders}",
        "order-food-basket-name": "\n .label = Your name\n .emptyErrorText = This field is required",
        "order-food-basket-email": "\n .label = Your email\n .emptyErrorText = This field is required\n .invalidErrorText = Invalid email",
        "order-food-basket-phone": "\n .label = Phone number (optional)",
        "order-food-basket-comment": "\n .label = Write a comment",
        "order-food-basket-purchase-button": "Complete payment\n .loadingLabel = Performing purchase...",
        "order-food-basket-seller":"Seller: {$seller}",
        "order-food-canteen-details-contact": "Contact",
        "order-food-canteen-details-address": "Address",
        "order-food-canteen-details-closed": "Closed",
        "order-food-receipt-scan-to-checkin": "Scan to check-in",
        "order-food-receipt-scan-to-checkin-offline": "The QR-code could not be loaded.",
        "order-food-featured-deliverydate": "Choose delivery date",
        "order-food-upcoming-menus": "Upcoming menus",
        "order-food-empty-state-text": "Kitchen does not offer food today. Try another day.",
        "order-food-sold-out-snackbar": "Item is out of stock",

        "product-unavailable-product-not-found-modal": "\n .title = Product not found\n .message = The requested product is not included in the catalog for the chosen date. Contact the kitchen.\n .buttonLabel = Understood",
        "product-unavailable-product-unavailable-modal": "\n .buttonLabel = Understood",


        // Product modal
        "canteenProductModal-preparation-label": "Preparation",
        "canteenProductModal-declaration-label": "Declaration",
        "canteenProductModal-allergens-label": "Allergens",


        // Proof of Purchase
        "proof-of-purchase-order-id": "Order id: { NUMBER($orderId, useGrouping: 0) }",
        "proof-of-purchase-open-receipt-button": "Show Receipt",
        "proof-of-purchase-order-type-lunch": "{ -order-type-lunch }",
        "proof-of-purchase-order-type-takeaway": "{ -order-type-take-away }",
        "proof-of-purchase-order-type-catering": "{ -order-type-catering }",
        "proof-of-purchase-order-type-meeting": "{ -order-type-meeting }",
        "proof-of-purchase-order-type-refill-account": "{ -gopay-wallet } { -gopay-wallet-refill(pos: \"initial\") }",
        "proof-of-purchase-order-type-auto-refill-account": "Automatic { -gopay-wallet-refill } subscription",
        "proof-of-purchase-order-type-lunch-subscription": "{ -order-type-lunch-subscription }",
        "proof-of-purchase-order-type-subscribe-payment-card": "{ -order-type-subscribe-payment-card }",
        "proof-of-purchase-order-type-refund": "{ -order-type-refund }",
        "receipt-qr-dialog-back": "Back",
        "receipt-no-deliveries": "The order does not contain deliveries",
        "receipt-company-orderlines": "Paid by company",

        "receipt-product-paid-by-free": "(for free)",
        "receipt-product-paid-by-credit-card": "(by\u00A0card)",
        "receipt-product-paid-by-one-click-payment": "(by\u00A0card)",
        "receipt-product-paid-by-mobile-pay": "(by\u00A0MobilePay)",
        "receipt-product-paid-by-payroll-deduction": "(by\u00A0payroll)",
        "receipt-product-paid-by-lunch-registration": "(by\u00A0lunch registration)",
        "receipt-product-paid-by-guest-lunch-registration": "(by\u00A0lunch registration)",
        "receipt-product-paid-by-gopay-account": "(by\u00A0{ -gopay-wallet })",
        "receipt-product-paid-by-invoice": "(by\u00A0invoice)",


        // Receipt
        "receipt-title": "Receipt",
        "receipt-forward-receipt-dialog": "\n .title = { $receipts -> \n *[one] Send Receipt \n [other] Send Receipts \n } \n .cancelButtonLabel = Cancel\n .submitButtonLabel = Send\n .submitButtonLoadingLabel = Sending...",
        "receipt-forward-receipt-email-field": "\n .label = Email Address",
        "receipt-forward-receipt-email-field-is-required": "Email address is required",
        "receipt-forward-receipt-email-field-must-be-valid": "Invalid email address",

        // Purchase section
        "receipt-purchase-details-section": "\n .heading = Purchase Details",
        "receipt-purchase-payment-status-paid": "PAID",
        "receipt-purchase-payment-status-unpaid": "UNPAID",
        "receipt-purchase-payment-status-invoiced": "INVOICED",
        "receipt-purchase-payment-status-refunded": "REFUNDED",
        "receipt-purchase-payment-status-cancelled": "REFUNDED",
        "receipt-purchase-address-item": "\n .label = Location:",
        "receipt-purchase-seller-vat-number": "VAT\u00A0No:\u00A0{ $vatNumber }",
        "receipt-purchase-customer-item": "\n .label = Customer:",
        "receipt-purchase-time-item": "\n .label = Purchase time:",
        "receipt-purchase-payment-method-item": "\n .label = Payment method:",
        "receipt-purchase-order-id-item": "\n .label = Order id:",
        "receipt-purchase-shop-channel-item": "\n .label = Channel:",
        "receipt-purchase-cost-center-item": "\n .label = Cost center:",

        // Actions section
        "receipt-actions-forward-receipt-button": "Send Receipt",
        "receipt-actions-cancel-order-button": "Cancel Order",
        "receipt-actions-confirm-cancel-dialog": "\n .description = You are about to cancel your order. Are you sure?\n .confirmLabel = Yes\n .cancelLabel = No\n .loadingDescription = Cancelling order...",
        "receipt-actions-cancel-unavailable-dialog": "\n .buttonLabel = Understood",

        // Delivery section
        "receipt-delivery-details-section": "\n .heading = Delivery",
        "receipt-delivery-order-type-item": "\n .label = Order type:",
        "receipt-order-type-lunch": "{ -order-type-lunch }",
        "receipt-order-type-takeaway": "{ -order-type-take-away }",
        "receipt-order-type-catering": "{ -order-type-catering }",
        "receipt-order-type-meeting": "{ -order-type-meeting }",
        "receipt-order-type-refill-account": "{ -gopay-wallet } { -gopay-wallet-refill(pos: \"initial\") }",
        "receipt-order-type-auto-refill-account": "Automatic { -gopay-wallet-refill } subscription",
        "receipt-order-type-lunch-subscription": "{ -order-type-lunch-subscription }",
        "receipt-order-type-subscribe-payment-card": "{ -order-type-subscribe-payment-card }",
        "receipt-order-type-refund": "{ -order-type-refund }",
        "receipt-delivery-type-item": "\n .label = Delivery type:",
        "receipt-delivery-type-pick-up": "Pick up",
        "receipt-delivery-time-item": "\n .label = Delivery time:",
        "receipt-delivery-comment-item": "\n .label = Comment:",

        // Order details section
        "receipt-payment-method-free": "Free",
        "receipt-payment-method-credit-card": "Credit card",
        "receipt-payment-method-one-click-payment": "Credit card",
        "receipt-payment-method-mobile-pay": "MobilePay",
        "receipt-payment-method-payroll-deduction": "Payroll deduction",
        "receipt-payment-method-lunch-registration": "Lunch registration",
        "receipt-payment-method-guest-lunch-registration": "Lunch registration",
        "receipt-payment-method-gopay-account": "{ -gopay-wallet }",
        "receipt-payment-method-invoice": "Invoice",

        "receipt-order-details-buyerparty-private": "Private purchase",
        "receipt-order-details-buyerparty-company": "Company purchase",
        "receipt-order-details-vat-total": "\n .label = Contained VAT:",

        // Accounting details section
        "receipt-accounting-details-section": "\n .heading = Accounting",


        // Order details
        "orderDetails-deliveryType-delivery": "\n .title = Delivery",
        "orderDetails-deliveryType-pick-up": "\n .title = Pick up",
        "orderDetails-deliveryType-eating-in-canteen": "\n .title = Eat in canteen",
        "orderDetails-attendees-subject-label" : "\n    .title = Participants ({$count})",
        "orderDetails-note-label" : "\n    .title = Note",
        "orderDetails-catering-totalAmountIncludeVat-label" : "Amount incl. vat",
        "orderDetails-header-label" : "\n    .title = My meeting",
        "orderDetails-meeting-privacyStatus-label" : "\n    .label = Private",
        "orderDetails-resources-subject-label" : "Resources",


        // Confirm delete meeting dialog
        "deleteMeeting-labels" : "\n    .title = Are you sure you want to delete meeting?\n    .buttonLabel = Confirm",


        // Confirmation-dialog
        "confirmation-dialog-cancel": "Cancel",


        // Notifications
        "notifications-page-title": "\n .primaryTopBarTitle = Messages",
        "notifications-no-notifications-label" : "No messages",


        // Room Finder
        "roomfinder-tabs-searcher": "Search",
        "roomfinder-tabs-overview": "Overview",
        "roomfinder-overview-find-room": "\n    .label = Find room",
        // "roomFinder-availableResourceGroup-1": "Available Rooms",
        // "roomFinder-availableResourceGroup-2": "Available AV Equipments",
        // "roomFinder-availableResourceGroup-4": "Available Cars",


        // Basket
        "basket-removeAll-label" : "Remove all",


        // Week menu
        "home-week-menu-title": "\n .title = Week menu",
        "weekMenu-badge": "Week menu",
        "weekMenu-header-label": "\n .title = Menu of the week",
        "weekMenu-no-menu-label": "Unfortunately, no menus have been created this week.",
        "weekMenu-no-menu-subtitle": "Here you will be able to see the menu for the upcoming days if available. You cannot purchase from here.",


        // Meeting options
        "meetingOptions-extendMeeting-label": "Extend meeting",
        "meetingOptions-endMeeting-label": "End meeting",
        "meetingOptions-viewDetails-label": "View meeting details",
        "meetingOptions-delete-label": "Delete meeting",
        "meetingOptions-edit-label": "Edit meeting",
        "meetingOptions-assistance-label": "Assistance",
        "-meetingOptions-startMeeting-base-label": "Start meeting",
        "meetingOptions-startMeeting-expired-label": "{-meetingOptions-startMeeting-base-label} (expired)",
        "meetingOptions-startMeeting-left-label": "{-meetingOptions-startMeeting-base-label} ({$time}} left)",
        "meetingOptions-startMeeting-in-label": "{-meetingOptions-startMeeting-base-label} (in {$time}})",


        // News
        "allNews-header-label": "\n    .title = News",
        "allNews-noNews-label": "No news",
        "newsDetails-header-label": "\n    .title = My News",
        "newsDetails-by-label": "By {$creator} | {$company}",


        // Misc
        "action-sheet-cancel-button": "Cancel",
        "alert-dialog-close-button": "Close",
        "options-novalue" : "-- Select --",
        "date-time-picker": "\n .okLabel = OK\n .cancelLabel = Cancel",


        "completeBooking-advancedOptions-button-label" : "Next",
        "completeBooking-advancedOptions-header-label" : "More options",
        "completeBooking-advancedOptions-privateMeeting-label" : "Private meeting",
        "completeBooking-advancedOptions-sittingsArrangement-label" : "Sitting arrangement",
        "completeBooking-header-label" : "\n    .title = Complete booking",
        "completeBooking-meeting-endTime-label" : "To",
        "completeBooking-meeting-header-label" : "Details",
        "completeBooking-meeting-meetingName-label" : "Meeting name",
        "completeBooking-meeting-startTime-label" : "From",

        "assistance-header-label": "\n    .title = Request Assistance",
        "assistance-location-label": "Location:",
        "assistance-noLocations-label": "No locations",
        "assistance-button-next-label" : "Next",
        "assistance-chooseMessage-label" : "Choose message:",
        "assistance-yourMessage-label" : "Your message:",
        "assistance-writeMessage-label" : "Write your own message",

        "confirmBooking-header-label" : "\n    .title = Confirm Booking",
        "confirmBooking-button-label" : "Complete order",
        "confirmBooking-empty-basket-label" : "Basket is empty",
        "endMeeting-button-label" : "Confirm",
        "endMeeting-header-label" : "End meeting",
        "endMeeting-success-label" : "The meeting is ended",
        "endMeeting-text-label" : "Are you sure you want to end the meeting?",
        "endMeeting-labels" : "\n    .title = {endMeeting-text-label}\n    .title = {endMeeting-button-label}",
        "startMeeting-button-label" : "Confirm",
        "startMeeting-text-label" : "Are you sure that you want to start this meeting?",
        "startMeeting-labels" : "\n    .title = {startMeeting-text-label}\n    .title = {startMeeting-button-label}",
        "extendMeeting-button-label" : "Confirm",
        "extendMeeting-changeEndTimeTo-label" : "Update meeting end time to:",
        "extendMeeting-header-label" : "\n    .title = Extend meeting",
        "extendMeeting-success-label" : "Meeting is extended",
        "meeting-button-end-label" : "End",
        "meeting-button-extend-label" : "Extend",
        "meetingUpdated-button-label" : "Close",
        "meetingUpdated-header-label" : "Completed",
        "myMeetings-button-delete-label" : "Delete",
        "myMeetings-button-update-label" : "Update",
        "myMeetings-header-label" : "Filter",
        "myMeetings-search-label" : "Search",
        "roomFinder-button-addResource-label" : "Add",
        "roomFinder-button-removeResource-label" : "Remove",
        "roomFinder-button-next-label" : "Next",
        "roomFinder-resourceInfo-av-subject-label" : "Av-equipment",
        "roomFinder-resourceInfo-availableTimeSlots-subject-label" : "Available times",
        "roomFinder-resourceInfo-moreTimeSlots-subject-label" : "More times...",
        "roomFinder-resourceInfo-noAvailableTimeSlots-subject-label" : "No available times",
        "roomFinder-resourceInfo-sittingsArrangements-subject-label" : "Table arrangements",

        "roomFinder-specifyTime-header-label" : "\n    .title = Specify time",

        "roomFinder-search-filter-label" : "Filter",
        "roomFinder-search-nofilter-label" : "No filters",
        "roomFinder-search-unsupported-element" : "Unsupported element type: {$type}",
        "roomFinder-search-hour-label" : "hour",
        "roomFinder-search-hours-label" : "hours",
        "roomFinder-continue-to-checkout-label" : "Continue to check out ({$amount})",
        "roomFinder-subject-label" : "Available {resourceType}",
        "roomFinderFilter-fixedAv-label" : "Av-equipment",
        "roomFinderFilter-floor-label" : "Floor",
        "roomFinderFilter-group-button-label" : "Show",
        "roomFinderFilter-group-fixedAv-label" : "Av-equipment",
        "roomFinderFilter-group-floor-label" : "Floor",
        "roomFinderFilter-group-location-label" : "Locations",
        "roomFinderFilter-group-resourceTypes-label" : "Ressource type",
        "roomFinderFilter-group-tag-label" : "Tags",
        "roomFinderFilter-header-label" : "Filter",
        "roomFinderFilter-location-label" : "Locations",
        "roomFinderFilter-meetingDuration-hour-label" : "Hour",
        "roomFinderFilter-meetingDuration-hours-label" : "Hours",
        "roomFinderFilter-meetingDuration-subject-label" : "Duration",
        "roomFinderFilter-removeAll-label" : "Clear all",
        "roomFinderFilter-remove-label" : "Clear",
        "roomFinderFilter-resourceTypes-av-label" : "Av-equipment",
        "roomFinderFilter-resourceTypes-car-label" : "Car",
        "roomFinderFilter-resourceTypes-label" : "Resource type",
        "roomFinderFilter-resourceTypes-room-label" : "Room",
        "roomFinderFilter-resourceTypes-subject-label" : "Ressource types",
        "roomFinderFilter-room-label" : "Room",
        "roomFinderFilter-room-subject-label" : "Room",
        "roomFinderFilter-sittings-numberOfSittings-label" : "Number of sittings",
        "roomFinderFilter-sittings-subject-label" : "Sittings",
        "roomFinderFilter-tag-label" : "Tags",
        "roomFinderFilter-dateTimePicker-change-date-button": "\n .buttonLabel = Change date",
        "upcomingMeetings-button-end-label" : "End",
        "upcomingMeetings-button-extend-label" : "Extend",
        "upcomingMeetings-meetingStatus-label" : "Now",
        "upcomingMeetings-moreInfo-label" : "More info",


        // Meetings
        "meetingStarted-labels": "\n    .topbarTitle = Meeting Started\n    .title = The meeting has started\n    .buttonLabel = Continue",
        "meetingEnded-labels": "\n    .topbarTitle = Meeting Ended\n    .title = The meeting has ended\n    .buttonLabel = Continue",
        "meetingDeleted-labels": "\n    .topbarTitle = Meeting Deleted\n    .title = The meeting has been deleted\n    .buttonLabel = Continue",
        "meetingBooked-labels": "\n    .topbarTitle = Meeting Booked\n    .buttonLabel = Next",
        "meetingUpdated-labels": "\n    .topbarTitle = Meeting Updated\n    .buttonLabel = Next",
        "meeting-open-in-calendar": "Open in calendar",

        "hours": "hours",
    },
};
