import React, { useCallback } from "react"
import clsx from "clsx"
import { Box, Grid, Typography } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBagShopping, faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { IRecentPurchase, IRecentPurchaseDetails, RecentPurchaseStatus } from "shared/Modules/UserCards/userCardsTypes"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import Card from "shared/Components/Card/Card"

type StyleProps = {
    bg: string
    statusColor: string
    badgeText: string
}

const useStyles = makeStyles(() =>
    createStyles({
        card: (props: StyleProps) => ({
            padding: "1rem",
            borderRadius: "8px",
            flexWrap: "nowrap",
            gap: "1rem",
            backgroundColor: props.bg,
        }),
        statusBadge: (props: StyleProps) => ({
            padding: ".25rem .625rem",
            borderRadius: ".25rem",
            fontSize: ".875rem",
            fontWeight: 500,
            letterSpacing: "0.5px",
            backgroundColor: props.statusColor,
            color: props.badgeText,
        }),
        detailsWrap: (props: StyleProps) => ({
            paddingLeft: ".5rem",
            gap: ".25rem",
            borderLeft: `3px solid ${props.statusColor}`,
        }),
        cardInfoWrap: {
            gap: ".5rem",
        },
        subTitle: {
            color: "rgba(0, 0, 0, 0.87)",
        },
        buttonWrap: {
            gap: ".5rem",
        },
        descriptionText: {
            color: "rgba(0, 0, 0, 0.6)",
        },
    })
)

type RecentPurchaseCardProps = Readonly<{
    card: IRecentPurchase
    showDetailedReceiptModal: (order: IRecentPurchaseDetails) => void
}>

export function RecentPurchaseCard({
    card: { recentPurchaseDetails },
    showDetailedReceiptModal,
}: RecentPurchaseCardProps) {
    const { orderCreated, status, statusDescription } = recentPurchaseDetails.content
    const dateTimeFactory = useDateTime()

    const STATUS_STYLES: Record<RecentPurchaseStatus, StyleProps> = {
        [RecentPurchaseStatus.RECEIVED]: {
            bg: "#DBEAFE",
            statusColor: "#BFDBFE",
            badgeText: "#1E3A8A",
        },
        [RecentPurchaseStatus.PAID]: {
            bg: "#D1FAE5",
            statusColor: "#A7F3D0",
            badgeText: "#064E3B",
        },
        [RecentPurchaseStatus.FAILED]: {
            bg: "#FEE2E2",
            statusColor: "#FECACA",
            badgeText: "#7F1D1D",
        },
    }

    const classes = useStyles(STATUS_STYLES[status])

    const handleReceiptOpen = useCallback(
        (order) => showDetailedReceiptModal(order),
        [showDetailedReceiptModal, recentPurchaseDetails.content]
    )

    return (
        <Card width="100%" onClick={() => handleReceiptOpen(recentPurchaseDetails)}>
            <Grid container direction="row" className={classes.card}>
                <FontAwesomeIcon icon={faBagShopping} size="2xl" color="black" />
                <Grid container direction="column" alignItems="flex-start" className={classes.cardInfoWrap}>
                    <Typography variant="caption" className={classes.statusBadge}>
                        {status}
                    </Typography>
                    <Grid container direction="column" className={classes.detailsWrap}>
                        <Typography variant="body1" className={classes.subTitle}>
                            {dateTimeFactory.toFNDateTime(dateTimeFactory.fromFNDateTime(orderCreated))}
                        </Typography>
                        <Box>
                            <LocalizedStrict
                                id="home-user-card-recent-purchase-status"
                                vars={{ status: statusDescription }}
                            >
                                <Typography variant="caption" className={classes.descriptionText}>
                                    Status
                                </Typography>
                            </LocalizedStrict>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                className={clsx(classes.buttonWrap, classes.descriptionText)}
                            >
                                <LocalizedStrict id="home-user-card-recent-purchase-view-receipt">
                                    <Typography variant="caption">View receipt</Typography>
                                </LocalizedStrict>
                                <FontAwesomeIcon icon={faArrowRight} size="xs" />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}
