import clsx from "clsx"
import React, {
    Children,
    ComponentProps,
    ComponentPropsWithoutRef,
    createContext,
    FormEventHandler,
    forwardRef,
    Fragment,
    MouseEvent,
    PropsWithChildren,
    Ref,
    useCallback,
    useContext,
    useEffect,
    useMemo,
} from "react"
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
    Checkbox,
    CheckboxProps,
    CircularProgress,
    createStyles,
    Divider,
    Fade,
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormControlProps,
    FormHelperText,
    FormHelperTextProps,
    Grid,
    Link as MuiLink,
    LinkProps as MuiLinkProps,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Radio,
    TextField,
    TextFieldProps,
    Theme,
    Typography,
    TypographyProps,
} from "@material-ui/core"
import MuiPhoneNumber, { MaterialUiPhoneNumberProps } from "material-ui-phone-number"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IPlayerProps, Player } from "@lottiefiles/react-lottie-player"
import { StrictOmit } from "shared/Types/helperTypes"
import { WellknownHeight } from "shared/Helpers/constants"
import { Logger } from "shared/Helpers/logging"
import { childrenByElementType } from "shared/Helpers/reactLib"
import AssetHelper from "shared/Helpers/AssetHelper"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import PinFieldElement from "shared/Components/Form/PinFieldElement"
import { StandardLoadingButton, StandardLoadingButtonProps } from "shared/Components/Loading/LoadingButton"
import { LinkButton, LinkButtonProps } from "shared/Components/Button/LinkButton"
import Screen, { SingleChildProps } from "shared/Components/Skeleton/Screen"
import { AppLinkProps, ExternalLink } from "shared/Modules/Cordova/Components/AppLinks"
import { navigateBack } from "../loginActions"
import { CompanyChoice } from "../loginTypes"
import Logo from "shared/assets/images/gopay_logo.svg"

type StyleProps = Readonly<{
    hasVisual: boolean
}>

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            height: "100%",
        },
        outerGrid: {
            height: "100%",
            paddingTop: WellknownHeight.PRIMARY_TOP_BAR,
            // Subtract bottom padding already applied to action area grid items to make it 50px total
            paddingBottom: 50 - theme.spacing(2),
        },
        inputArea: (props: StyleProps) => ({
            order: 1,
            // When page has no visual, input area should not grow
            flexGrow: props.hasVisual ? 1 : undefined,
            paddingBottom: theme.spacing(2),
        }),
        inputAreaGridItem: {
            paddingBottom: theme.spacing(2),
        },
        progressArea: (props: StyleProps) => ({
            // When page has no visual action area comes first and then progress area
            order: props.hasVisual ? 2 : 3,
            flexGrow: 2,
            alignSelf: "center",
            // Make plenty of air between controls and progress indicator
            paddingTop: "10rem",
        }),
        actionArea: (props: StyleProps) => ({
            order: props.hasVisual ? 3 : 2,
        }),
        actionAreaGridItem: {
            paddingBottom: theme.spacing(2),
        },
        gridItemLogo: {
            alignSelf: "center",
        },
        logo: {
            height: "100px",
            marginBottom: theme.spacing(4),
        },
        formAnim: {
            height: "300px",
        },
        gridItemHeader: {},
        header: (props: StyleProps) => ({
            textAlign: props.hasVisual ? "center" : "left",
            fontWeight: 800,
            color: theme.palette.grey[800],
            paddingTop: props.hasVisual ? theme.spacing(5) : undefined,
            paddingBottom: theme.spacing(3),
        }),
        formPara: {
            textAlign: "left",
            fontSize: "1.1rem",
            color: theme.palette.grey[900],
            margin: 0,
        },
        inputText: {
            minHeight: "4rem",
        },
        inputIcon: {
            marginBottom: 5,
        },
        inputPin: {
            paddingTop: theme.spacing(3),
        },
        inputOtpFont: {
            fontFamily: "monospace !important",
            fontSize: theme.typography.h2.fontSize,
            letterSpacing: "1.2rem",
        },
        inputOtp: {
            background: "transparent",
            "&::after": {
                content: '"------"',
                position: "absolute",
                bottom: 0,
                left: 0,
                fontFamily: "monospace !important",
                fontSize: theme.typography.h2.fontSize,
                lineHeight: "0.3rem",
                letterSpacing: "1.2rem",
            },
        },
        termsInput: {
            marginTop: theme.spacing(1),
        },
        termsLabel: {
            paddingLeft: theme.spacing(1),
        },
        formLink: {
            display: "block",
            textAlign: "center",
            letterSpacing: -0.25,
            paddingTop: theme.spacing(3),
        },
        listItemSecondaryAction: {
            right: 0,
        },
    }),
)

type LoginFormContextProps = Readonly<{
    classes: ReturnType<typeof useStyles>
}>

const LoginFormContext = createContext<LoginFormContextProps | undefined>(undefined)
const logger = new Logger("login")

type LoginScreenProps = Readonly<
    Pick<ComponentProps<typeof Screen>, "children" | "alternativeHeaderElement"> & {
        backButtonNavigatesTo: "nothing" | "welcome" | "choices" | "previous" | "previous-preserve-state"
    }
>

export function LoginScreen({ alternativeHeaderElement, backButtonNavigatesTo, children }: LoginScreenProps) {
    const { goToLogin, goToHomeNoGuest, goToPrevious } = useAppNavigation()
    const dispatch = useDispatch()

    const FadeIn = useCallback(
        ({ children }: SingleChildProps) => (
            <Fade timeout={1000} in>
                {children}
            </Fade>
        ),
        [],
    )

    function handleBackButtonClick() {
        // Reset state that should not be kept when going backwards in the flow such as API response
        if (backButtonNavigatesTo !== "previous-preserve-state") {
            dispatch(navigateBack())
        }

        if (backButtonNavigatesTo === "welcome") goToLogin()
        else if (backButtonNavigatesTo === "choices") goToHomeNoGuest()
        else if (backButtonNavigatesTo === "previous" || backButtonNavigatesTo === "previous-preserve-state")
            goToPrevious()
    }

    return (
        <Screen
            name="Login"
            showPrimaryTopBar
            alternativeHeaderElement={alternativeHeaderElement}
            onBackButtonPress={backButtonNavigatesTo !== "nothing" ? handleBackButtonClick : undefined}
            transition={FadeIn}
            containerPadding={30}
            fullHeight
            fitPage
        >
            {children}
        </Screen>
    )
}

type LoginContextProviderProps = StyleProps & Readonly<PropsWithChildren<{}>>

export function LoginContextProvider({ hasVisual, children }: LoginContextProviderProps) {
    const classes = useStyles({ hasVisual })

    return <LoginFormContext.Provider value={{ classes }}>{children}</LoginFormContext.Provider>
}

type FormLogoProps = Readonly<Pick<ComponentPropsWithoutRef<"img">, "onClick">>

export function FormLogo({ onClick }: FormLogoProps) {
    const context = useContext(LoginFormContext)

    return <img src={AssetHelper.url(Logo)} className={context?.classes.logo} onClick={onClick} />
}

type FormAnimProps = Readonly<StrictOmit<IPlayerProps, "autoplay" | "loop">>
export function FormAnim(props: FormAnimProps) {
    const context = useContext(LoginFormContext)

    return <Player autoplay loop className={context?.classes.formAnim} {...props} />
}

type FormInputAreaProps = Readonly<PropsWithChildren<{}>>
export function FormInputArea({ children }: FormInputAreaProps) {
    const context = useContext(LoginFormContext)

    return (
        <>
            {Children.map(children, (child, index) => (
                <Grid key={index} item className={context?.classes.inputAreaGridItem}>
                    {child}
                </Grid>
            ))}
        </>
    )
}

type FormActionAreaProps = Readonly<PropsWithChildren<{}>>
export function FormActionArea({ children }: FormActionAreaProps) {
    const context = useContext(LoginFormContext)

    return (
        <>
            {Children.map(children, (child, index) => (
                <Grid key={index} item className={context?.classes.actionAreaGridItem}>
                    {child}
                </Grid>
            ))}
        </>
    )
}

type LoginFormProps = Readonly<
    PropsWithChildren<{
        onSubmit?: FormEventHandler<HTMLFormElement>
    }>
>

export function LoginForm({ children, onSubmit }: LoginFormProps) {
    const childGroups = childrenByElementType(children, [])
    const hasVisual = childGroups.has(FormLogo) || childGroups.has(FormAnim)

    const classes = useStyles({ hasVisual })

    return (
        <LoginFormContext.Provider value={{ classes }}>
            <form onSubmit={onSubmit} className={classes.form}>
                <Grid container direction="column" wrap="nowrap" className={classes.outerGrid}>
                    <Grid
                        item
                        container
                        direction="column"
                        wrap="nowrap"
                        justifyContent={hasVisual ? "center" : "flex-start"}
                        className={classes.inputArea}
                    >
                        {childGroups.has(FormLogo) &&
                            childGroups.get(FormLogo)?.map((child, index) => (
                                <Grid key={index} item className={classes.gridItemLogo}>
                                    {child}
                                </Grid>
                            ))}
                        {childGroups.has(FormAnim) &&
                            childGroups.get(FormAnim)?.map((child, index) => (
                                <Grid key={index} item className={classes.gridItemLogo}>
                                    {child}
                                </Grid>
                            ))}
                        {childGroups.has(FormHeader) &&
                            childGroups.get(FormHeader)?.map((child, index) => (
                                <Grid key={index} item className={classes.gridItemHeader}>
                                    {child}
                                </Grid>
                            ))}
                        {childGroups.has(FormInputArea) &&
                            childGroups
                                .get(FormInputArea)
                                ?.map((child, index) => <Fragment key={index}>{child}</Fragment>)}
                    </Grid>
                    <Grid item container direction="column" wrap="nowrap" className={classes.actionArea}>
                        {childGroups.has(FormActionArea) &&
                            childGroups
                                .get(FormActionArea)
                                ?.map((child, index) => <Fragment key={index}>{child}</Fragment>)}
                    </Grid>
                    {childGroups.has(FormProgress) &&
                        childGroups.get(FormProgress)?.map((child, index) => (
                            <Grid key={index} item className={classes.progressArea}>
                                {child}
                            </Grid>
                        ))}
                </Grid>
            </form>
        </LoginFormContext.Provider>
    )
}

type FormHeaderProps = Readonly<PropsWithChildren<{}>> & Pick<TypographyProps, "onClick">

export function FormHeader({ onClick, children }: FormHeaderProps) {
    const context = useContext(LoginFormContext)

    return (
        <Typography variant="h5" className={context?.classes.header} onClick={onClick}>
            {children}
        </Typography>
    )
}

type ParagraphProps = ComponentPropsWithoutRef<"p">
type FormParagraphProps = Readonly<Pick<ParagraphProps, "style"> & PropsWithChildren<{}>>

export const FormParagraph = forwardRef(({ style, children }: FormParagraphProps, ref: Ref<HTMLParagraphElement>) => {
    const context = useContext(LoginFormContext)
    return (
        <Typography ref={ref} variant="body1" className={context?.classes.formPara} style={style}>
            {children}
        </Typography>
    )
})

type FormTextFieldProps = Readonly<
    StrictOmit<TextFieldProps, "fullWidth" | "InputProps" | "className"> & {
        icon?: IconProp
    }
>

export function FormTextField({ icon, ...rest }: FormTextFieldProps) {
    const context = useContext(LoginFormContext)
    const adornment = icon ? (
        <FontAwesomeIcon className={context?.classes.inputIcon} icon={icon} color="#aaaaaa" size="1x" />
    ) : undefined

    return (
        <TextField
            fullWidth
            InputProps={{ endAdornment: adornment }}
            className={context?.classes.inputText}
            {...rest}
        />
    )
}

type FormOneTimeCodeFieldProps = Readonly<
    StrictOmit<TextFieldProps, "variant" | "fullWidth" | "autoFocus" | "autoComplete" | "inputProps"> & {
        codeLength: number
        value: string
        setValue: (value: unknown, shouldValidate?: boolean) => void
        submitForm: () => void
    }
>

export function FormOneTimeCodeField({ codeLength, value, setValue, submitForm, ...rest }: FormOneTimeCodeFieldProps) {
    const requestCredentials = useMemo(() => new AbortController(), [])

    useEffect(() => {
        // Attempt to use WebOTP to automatically fetch code
        if ("OTPCredential" in window) {
            logger.info("Attempting to get code via WebOTP...")
            navigator.credentials
                .get({
                    otp: {
                        transport: ["sms"],
                    },
                    signal: requestCredentials.signal,
                })
                .then((otp) => {
                    setValue(otp?.code ?? "")
                })
                .catch((e) => logger.error("Failed to get OTP code", e))
        } else {
            logger.info("WebOTP not available")
        }
    }, [])

    useEffect(() => {
        if (value.length >= codeLength) {
            requestCredentials.abort()
            submitForm()
        }
    }, [codeLength, value, requestCredentials, submitForm])

    return (
        <TextField
            variant="standard"
            fullWidth
            autoFocus
            // On Safari this enables automatic fetch of code via autocomplete
            autoComplete="one-time-code"
            inputProps={{ inputMode: "numeric" }}
            value={value}
            {...rest}
        />
    )
}

type FormPinFieldProps = Readonly<Pick<ComponentProps<typeof PinFieldElement>, "length" | "onComplete">>

export function FormPinField({ length, onComplete }: FormPinFieldProps) {
    const context = useContext(LoginFormContext)
    return (
        <PinFieldElement
            length={length}
            validate="0123456789"
            onComplete={onComplete}
            className={context?.classes.inputPin}
        />
    )
}

type PhoneInputCountry = Readonly<{
    name: string
    dialCode: string
    countryCode: string
}>

type FormPhoneInputProps = Readonly<
    StrictOmit<MaterialUiPhoneNumberProps, "onChange" | "fullWidth"> & {
        onChange?: (value: string) => void
    }
>

export function FormPhoneInput({ onChange, ...rest }: FormPhoneInputProps) {
    const context = useContext(LoginFormContext)

    function handleChange(value: any, country: PhoneInputCountry) {
        // For some reason value is not always a string
        if (typeof value === "string") {
            // Component can contain country code even when otherwise empty
            // To simplify validation, remove country code if it is the only
            // content
            const valueToUse = value === `+${country.dialCode}` ? "" : value
            onChange?.(valueToUse)
        }
    }

    return <MuiPhoneNumber onChange={handleChange as any} fullWidth {...rest} />
}

type FormCheckboxProps = Readonly<
    Pick<CheckboxProps, "id" | "name" | "checked" | "onChange"> &
        Pick<FormControlProps, "error"> & {
            helperText?: FormHelperTextProps["children"]
            children?: FormControlLabelProps["label"]
            onLabelClick?: (e: MouseEvent<HTMLLabelElement>) => void
        }
>

export function FormCheckbox({ error, helperText, children, onLabelClick, ...rest }: FormCheckboxProps) {
    return (
        <FormControl error={error}>
            <FormControlLabel label={children} control={<Checkbox {...rest} />} onClick={onLabelClick} />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}

type SubmitButtonProps = Pick<
    StandardLoadingButtonProps,
    "autoFocus" | "loading" | "loadingLabel" | "disabled" | "style" | "children"
>
export const SubmitButton = forwardRef(
    ({ loading, loadingLabel, disabled, style, children }: SubmitButtonProps, ref: Ref<HTMLButtonElement>) => {
        return (
            <StandardLoadingButton
                ref={ref}
                type="submit"
                loading={loading}
                loadingLabel={loadingLabel}
                disabled={disabled}
                style={style}
            >
                {children}
            </StandardLoadingButton>
        )
    },
)

type FormLinkProps = Readonly<
    Pick<RouterLinkProps, "to"> & StrictOmit<MuiLinkProps<"a">, "className" | "color" | "underline">
>

export function FormLink({ to, children, ...rest }: FormLinkProps) {
    const context = useContext(LoginFormContext)

    return (
        <MuiLink
            component={RouterLink}
            to={to}
            color="textPrimary"
            underline="always"
            className={context?.classes.formLink}
            {...rest}
        >
            {children}
        </MuiLink>
    )
}

export function FormLinkButton({ children, ...rest }: LinkButtonProps) {
    return <LinkButton {...rest}>{children}</LinkButton>
}

type FormExternalLinkProps = Readonly<
    Pick<AppLinkProps, "href" | "children"> & {
        visible?: boolean
    }
>

export function FormExternalLink({ visible, href, children }: FormExternalLinkProps) {
    const context = useContext(LoginFormContext)
    const visibility = visible ?? true ? undefined : "hidden"
    return (
        <ExternalLink href={href} style={{ visibility }} className={context?.classes.formLink}>
            {children}
        </ExternalLink>
    )
}

type FormProgressProps = Readonly<{
    visible: boolean
}>

export function FormProgress({ visible }: FormProgressProps) {
    const visibility = visible ?? true ? undefined : "hidden"

    return <CircularProgress size={80} color="primary" style={{ visibility }} />
}

type FormLocationListItemProps = Readonly<{
    company: CompanyChoice
    checked?: boolean
    disabled?: boolean
    onClick?: () => void
    isAccountSelection?: boolean
}>

export function FormLocationListItem({
    company,
    checked,
    disabled,
    onClick,
    isAccountSelection = false,
}: FormLocationListItemProps) {
    const context = useContext(LoginFormContext)
    const addressLine = `${company.address?.streetName ?? ""} ${company.address?.streetNumber ?? ""}`.trim()

    return (
        <>
            <ListItem button disabled={disabled} onClick={onClick}>
                <ListItemText
                    primaryTypographyProps={{ variant: "body1" }}
                    primary={isAccountSelection ? company.user?.username : company.name}
                    secondaryTypographyProps={{ variant: "body2" }}
                    secondary={isAccountSelection ? company?.user?.displayName : addressLine}
                />
                <ListItemSecondaryAction classes={{ root: context?.classes.listItemSecondaryAction }}>
                    <Radio value={company.uid} checked={checked} disabled={disabled} onClick={onClick} />
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </>
    )
}
